import {
  Box,
  Circle,
  Flex,
  HStack,
  Spacer,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Link } from "react-router-dom";
import statusMap from '@components/common/StatusMap';
import StatusDisplay from '@components/Measures/StatusDisplay';
import { Datetime } from '@components/common/Datetime';
import Button from '@components/forms/Button';
import linkHoverStyles from '@components/common/Styles';

const MeasureRow = ({measure}) => {
  const MobileRow = () => {
    return (
      <Box
        key={measure.id}
        py={4}
        borderBottomWidth='1px'
        borderBottomStyle='solid'
        borderBottomColor='blackAlpha.200'
      >
        <Box mb={2}>
          <Link to={`/v2moms/${measure.v2momMethod.v2mom.id}`}>
            <Text fontSize='xs' color='blackAlpha.600' display='inline-block' _hover={linkHoverStyles}>
              {measure.v2momMethod.v2mom.name} - M{measure.v2momMethod.priority}: {measure.v2momMethod.name}
            </Text>
          </Link>
          <Text>{measure.name}</Text>
        </Box>
        <Flex align='center'>
          <Circle size={3} bg={statusMap[measure.status].background} mr={1.5} />
          <Text fontSize='sm'>{statusMap[measure.status].title} as of <Datetime timestamp={measure.updatedAt} format='date' /></Text>
          <Spacer />
          <Box fontSize='sm' align='right'>
            <Link to={`/v2moms/${measure.v2momMethod.v2mom.id}/update`}>
              <Button size='sm' variant='ghost'>Update</Button>
            </Link>
          </Box>
        </Flex>
      </Box>
    );
  }

  const FullRow = () => {
    return (
      <HStack
        key={measure.id}
        py={4}
        borderBottomWidth='1px'
        borderBottomStyle='solid'
        borderBottomColor='blackAlpha.200'
      >
        <Box w='60%' pr={4}>
          <Link to={`/v2moms/${measure.v2momMethod.v2mom.id}`}>
            <Text fontSize='xs' color='blackAlpha.600' display='inline-block' _hover={linkHoverStyles}>
              {measure.v2momMethod.v2mom.name} - M{measure.v2momMethod.priority}: {measure.v2momMethod.name}
            </Text>
          </Link>
          <Text fontSize='sm'>{measure.name}</Text>
        </Box>
        <Box w='20%' fontSize='sm'>
          <StatusDisplay type={measure.measureType} status={measure.status} complete={false} target={measure.target} current={measure.current} />
        </Box>
        <Box w='10%' fontSize='sm'>
          <Datetime timestamp={measure.updatedAt} format='date' />
        </Box>
        <Box w='10%' fontSize='sm' align='right'>
          <Link to={`/v2moms/${measure.v2momMethod.v2mom.id}/update`}>
            <Button size='xs' variant='ghost'>Update</Button>
          </Link>
        </Box>
      </HStack>
    );
  }

  const responsive = useBreakpointValue({ base: <MobileRow />, lg: <FullRow /> });

  return (
    <>{responsive}</>
  );
}

export default MeasureRow;