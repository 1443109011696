import { WhatAreTheComponents } from './general/WhatAreTheComponents';
import { WhatIsAV2mom } from './general/WhatIsAV2mom';
import { WhyCanIEdit } from './general/WhyCanIEdit';
import { WhatIsMarkdown } from './general/WhatIsMarkdown';
import { FoundABug } from './general/FoundABug';
import { FeatureRequest } from './general/FeatureRequest';
import { WhatIsAMethod } from './methods/WhatIsAMethod';
import { WhatIsAnObstacle } from './methods/WhatIsAnObstacle';
import { WhatIsAMeasure } from './measures/WhatIsAMeasure';
import { ProgressOrCompletion } from './measures/ProgessOrCompletion';
import { WhatAreStatuses } from './updates/WhatAreStatuses';
import { WhatMakesAGoodMeasure } from './measures/WhatMakesAGoodMeasure';

export const data = {
  general: {
    title: 'General',
    entries: [
      WhatIsAV2mom,
      WhatAreTheComponents,
      WhyCanIEdit,
      WhatIsMarkdown,
      FoundABug,
      FeatureRequest,
    ]
  },
  methods: {
    title: 'Methods',
    entries: [
      WhatIsAMethod,
      WhatIsAnObstacle
    ]
  },
  measures: {
    title: 'Measures',
    entries: [
      WhatIsAMeasure,
      WhatMakesAGoodMeasure,
      ProgressOrCompletion
    ]
  },
  updates: {
    title: 'Updates',
    entries: [
      WhatAreStatuses
    ]
  }
}