import { useEffect } from 'react';
import { useUserContext } from '@contexts/UserProvider';
import { useAuthContext } from '@contexts/AuthProvider';

import { Button } from '@components/forms';
import { Box, Flex, Heading } from '@chakra-ui/react';
import Shell from '@components/common/Shell';
import LoginButton from '@components/common/LoginButton';

const Login = () => {
  const { dispatch: userDispatch } = useUserContext();
  const { dispatch: authDispatch } = useAuthContext();

  useEffect(() => {
    authDispatch({ type: 'LOGOUT', payload: {} });
    userDispatch({ type: 'CLEAR_USER', payload: {} });
  },[authDispatch, userDispatch]);

  return (
    <Shell>
      <Flex direction="column" align='center'>
        <Heading textAlign="center" mb={8}>Please login.</Heading>
        <Box>
          <LoginButton As={Button} text='Login' />
        </Box>
      </Flex>
    </Shell>
  );
}

export default Login;