import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from "@okta/okta-react";
import { useLogin } from '@utils/Login';
import Loader from '@components/common/Loader';

const Authenticate = () => {
  const { authState } = useOktaAuth();
  const navigate = useNavigate();

  const [login, { loading, error, data }] = useLogin();
  const accessToken = authState["accessToken"]["accessToken"]

  useEffect(() => {
    login({ variables: {
      accessToken: accessToken,
    }});

    if (error) navigate('/login');
    if (data) navigate('/dashboard');
  }, [login, accessToken, data, error, navigate]);

  if (loading) return <Loader />;

  return ( null );
}

export default Authenticate;