import { Box, Flex } from '@chakra-ui/react';
import Header from './Header';
import Footer from './Footer';

const Shell = ({children}) => {
  return (
    <Flex flexDirection='column' height='100vh'>
      <Header />
      <Box flex='1'  width={{ lg: '4xl' }} maxW={{ lg: '4xl' }} mx={{ base: 8, lg: 'auto'}}>
        { children }
      </Box>
      <Footer />
    </Flex>
  );
}

export default Shell;