const statusMap = {
  'onTrack': {
    'title': 'On Track',
    'sentence': 'on track',
    'background': 'cyan.500',
    'text': 'white',
    'colorScheme': 'cyan',
  },
  'atRisk': {
    'title': 'At Risk',
    'sentence': 'at risk',
    'background': 'yellow.300',
    'text': 'black',
    'colorScheme': 'yellow',
  },
  'offTrack': {
    'title': 'Off Track',
    'sentence': 'off track',
    'background': 'red.500',
    'text': 'white',
    'colorScheme': 'red',
  },
  'notStarted': {
    'title': 'Not Started',
    'sentence': 'not started',
    'background': 'blue.500',
    'text': 'white',
    'colorScheme': 'blue',
  },
  'cancelled': {
    'title': 'Cancelled',
    'sentence': 'cancelled',
    'background': 'gray.300',
    'text': 'black',
    'colorScheme': 'gray',
  },
  'complete': {
    'title': 'Complete',
    'sentence': 'complete',
    'background': 'green.500',
    'text': 'white',
    'colorScheme': 'green',
  },
  'total': {
    'title': 'Total',
    'sentence': 'total',
    'background': 'green.500',
    'text': 'white',
    'colorScheme': 'green',
  }
}

export default statusMap;