import {
  Collapse,
  FormControl,
  Stack,
} from '@chakra-ui/react';
import { Button, Select, TextInput } from '@components/forms';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_USERS } from '@queries/Users';
import { ADD_MEASURE } from '@queries/V2moms';
import { useV2momContext } from '@contexts/V2momProvider';
import { useState } from 'react';
import { useNotifier } from '@utils/Notify';


const MeasureForm = ({dispatchType, closeModal, submitLabel, initialValues}) => {
  const { formSubmissionError, serverError } = useNotifier();
  const { state, dispatch } = useV2momContext();

  const isTypeProgress = (type) => {
    return type === 'progress';
  }
  const [ showTarget, setShowTarget ] = useState(isTypeProgress(initialValues.measureType));

  initialValues.v2momId = state.id

  const [addMeasure, { loading }] = useMutation(ADD_MEASURE,{
    onCompleted: (data) => {
      dispatch({ type: dispatchType, payload: { measure: data.addMeasure.measure } });
      closeModal();
    },
    onError: (error) => {
      serverError(error.message)
    },
  });

  function validate(values) {
    const errors = {};

    if (!values.name) {
      errors.name = 'Measure name is required';
    }
    if (values.measureType === 'progress' && !values.target) {
      errors.target = 'Measure target is required'
    }
    if (isNaN(values.target)) {
      errors.target = 'Target must be a number'
    }

    return errors;
  }

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: initialValues,
    validate,
    onSubmit: values => {
      addMeasure({ variables: {
        id: values.id,
        methodId: values.methodId,
        name: values.name,
        priority: values.priority,
        ownerId: values.ownerId,
        measureType: values.measureType,
        target: values.target
      }});
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (Object.keys(formik.errors).length !== 0) {
      formSubmissionError();
    }

    formik.handleSubmit();
  }

  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(GET_ALL_USERS, {});

  if (usersLoading) return <p>Loading ...</p>;
  if (usersError) return `${usersError}`;

  const userSelect = usersData.users.map(({ id, name }) => (
    <option key={id} value={id}>{name}</option>
  ));

  return (
    <form onSubmit={handleSubmit}>
      <FormControl>
        <TextInput
          label='Measure Name'
          id='name'
          value={formik.values.name}
          error={formik.touched.name && formik.errors.name ? formik.errors.name : null}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder='e.g., Employee engagement score of 75%'
          required
        />
        <Select
          label='Owner'
          id='ownerId'
          type='number'
          value={formik.values.ownerId ?? ''}
          error={formik.touched.ownerId && formik.errors.ownerId ? formik.errors.ownerId : null}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder='None'
        >
          {userSelect}
        </Select>
        <Select
          label='Type'
          id='measureType'
          value={formik.values.measureType ?? ''}
          error={formik.touched.measureType && formik.errors.measureType ? formik.errors.measureType : null}
          onBlur={formik.handleBlur}
          onChange={e => {
            formik.handleChange(e);
            setShowTarget(isTypeProgress(e.target.value));
          }}
          required
        >
          <option value='completion'>Completion</option>
          <option value='progress'>Progress</option>
        </Select>
        <Collapse in={showTarget} animateOpacity>
          <TextInput
            label='Target'
            id='target'
            type='number'
            value={formik.values.target}
            error={formik.touched.target && formik.errors.target ? formik.errors.target : null}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder='e.g., 75'
            required
          />
        </Collapse>
        <Stack direction='row' spacing={2} my={4} justifyContent='right'>
          <Button variant='ghost' onClick={closeModal}>Close</Button>
          <Button variant='primary' type='submit' isLoading={loading}>{submitLabel}</Button>
        </Stack>
      </FormControl>
    </form>
  );
}

export default MeasureForm;