import { useCallback, useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";

import oktaAuth from '@adapters/okta';
import { useAuthContext } from '@contexts/AuthProvider';
import { useNotifier } from '@utils/Notify';
import { useLogin } from '@utils/Login';

import { Countdown } from '@components/common/Datetime';
import { Button } from '@components/forms';
import LogoutButton from '@components/common/LogoutButton';

const useTimeout = () => {
  const { state: { expires }, dispatch: authDispatch } = useAuthContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { warning } = useNotifier();
  const navigate = useNavigate();
  const [login] = useLogin();

  const warningTimer = useRef(null);
  const urgentTimer = useRef(null);
  const expiredTimer = useRef(null);

  const onWarning = useCallback(() => {
    onOpen();
  },[onOpen])

  const onExpired = useCallback(() => {
    onClose();
    warning({
      title: 'Session timeout',
      description: "You've been automatically logged out due to inactivity",
      duration: null
    });
    authDispatch({ type: 'LOGOUT', payload: {} });
    navigate('/login');
  },[onClose, warning, authDispatch, navigate])

  const setTimers = useCallback((expires) => {
    const remaining = (expires*1000)-Date.now();

    warningTimer.current = setTimeout(() => { onWarning() }, remaining-(1000*60*5));
    urgentTimer.current = setTimeout(() => { onWarning() }, remaining-(1000*10));
    expiredTimer.current = setTimeout(() => { onExpired() }, remaining);
  },[onWarning, onExpired]);

  const clearTimers = () => {
    clearTimeout(warningTimer.current);
    clearTimeout(urgentTimer.current);
    clearTimeout(expiredTimer.current);
  }

  const refreshToken = () => {
    oktaAuth.tokenManager.renew('accessToken')
    .then((token) => {
      login({ variables: {
        accessToken: token["accessToken"],
      }});
      onClose();
    });
  }

  useEffect(() => {
    if (!expires) return;
    setTimers(expires);

    return () => {
      clearTimers();
    }
  },[expires, setTimers]);

  const TimeoutModal = () => {
    return (
      <Modal autoFocus={false} isOpen={isOpen} onClose={onClose} size='lg' motionPreset='slideInBottom' isCentered>
        <ModalOverlay />
        <ModalContent borderRadius='sm'>
          <ModalHeader>Your session is about to end.</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            You will lose any unsaved changes after your session ends. For your security, you will automatically be signed out in <Text as='span' fontWeight='bold'><Countdown expires={expires} /></Text>. 
            <Stack direction='row' spacing={2} my={4} justifyContent='right'>
              <LogoutButton As={Button} />
              <Button variant='primary' onClick={refreshToken}>Stay signed in</Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return { TimeoutModal }
};

export { useTimeout };