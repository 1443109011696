import { gql } from '@apollo/client';
import V2MOM_FRAGMENT from './FragmentV2mom';
import METHOD_FRAGMENT from './FragmentMethod';
import MEASURE_FRAGMENT from './FragmentMeasure';
import UPDATE_FRAGMENT from './FragmentUpdate';


const ADD_V2MOM = gql`
${V2MOM_FRAGMENT}
${METHOD_FRAGMENT}
${MEASURE_FRAGMENT}
${UPDATE_FRAGMENT}

mutation AddV2MOM(
  $id: ID
  $collectionId: ID!
  $name: String!
  $vision: String
  $values: String
) {
  addV2mom(input: {
    params: {
      id: $id,
      collectionId: $collectionId,
      name: $name,
      vision: $vision,
      values: $values,
    }
  }) {
    v2mom {
      ...CoreV2momFields
      v2momMethods {
        ...CoreMethodFields
        measures {
          ...CoreMeasureFields
          updates {
            ...CoreUpdateFields
          }
        }
      }
    }
  }
}
`;

export default ADD_V2MOM;