import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import {
  Box,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { MeasureTable, MeasureTableHeader } from '@components/Measures';
import MethodHeader from './MethodHeader';
import Obstacles from './Obstacles';

const Method = ({method}) => {
  return (
    <Box key={method.id} mb={12}>
      <Accordion allowToggle>
        <AccordionItem borderStyle='none'>
          <Flex>
            <Box>
              <MethodHeader method={method} />
            </Box>
            <Spacer />
            <Box>
              <AccordionButton borderRadius='full' p={2}>
                <AccordionIcon />
              </AccordionButton>
            </Box>
          </Flex>
          <AccordionPanel pl={0} pb={8}>
            <Obstacles obstacles={method.obstacles} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      
      <MeasureTableHeader />
      <MeasureTable methodId={method.id} />
    </Box>
  );
}

export default Method;