import {
  Flex,
  Icon,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { BsGithub, BsSlack } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useAuthState } from '@contexts/AuthProvider';
import linkHoverStyles from './Styles';

const Footer = () => {
  const { isAuthenticated } = useAuthState();

  const FooterLinks = () => {
    if (isAuthenticated) {
      return (
        <>
          <Spacer />
          <Stack direction='row' align='center' spacing={4}>
            <Link to="/help">
              <Text fontSize='sm' _hover={linkHoverStyles}>Help</Text>
            </Link>
            <a href="https://firehydrant.atlassian.net/servicedesk/customer/portal/2/group/9/create/70">
              <Text fontSize='sm' _hover={linkHoverStyles}>Submit an issue</Text>
            </a>
            <a href="https://firehydrant.slack.com/archives/C02SNKYGV2N">
              <Icon as={BsSlack} w={4} h={4} _hover={{ color: 'green.600' }} />
            </a>
            <a href="https://github.com/amateurhuman/polaris">
              <Icon as={BsGithub} w={4} h={4} _hover={{ color: 'green.600' }} />
            </a>
          </Stack>
        </>
      );
    }

    return <></>;
  }
  return (
    <Flex p={8} direction={{ base: 'column-reverse', lg: 'row' }}>
      <Text fontSize='sm'>Proprietary and Confidential &copy; 2022 FireHydrant, Inc.</Text>
      <Spacer />
      <Text fontSize='sm'>Built with &#128293; in NYC</Text>
      <FooterLinks />
    </Flex>
  );
}

export default Footer;