import {
  Box,
  Heading,
} from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import 'github-markdown-css/github-markdown-light.css';

import { useV2momContext } from '@contexts/V2momProvider';
import V2momHeader from './V2momHeader';
import ActionButtons from './ActionButtons';
import { Progress as V2momProgress } from '@components/V2mom';
import { DetailPlaceholder } from '@components/V2mom';
import MethodsHeader from '@components/Methods/MethodsHeader';
import MethodList from '@components/Methods/MethodList';

const V2momBuilder = () => {
  const { state: v2mom } = useV2momContext();

  const DisplayContent = ({content, emptyMessage}) => {
    if (content === '' || content === null) {
      content = '_Nothing to see here, yet._'

      if (v2mom.edit) {
        return <DetailPlaceholder message={emptyMessage} />;
      }
    }
    return <ReactMarkdown children={content} remarkPlugins={[remarkGfm]} />;
  }

  return (
    <Box>
      <V2momHeader />
      <Box>
        <V2momProgress v2mom={v2mom} />
      </Box>
      <ActionButtons />
      <Box mb={12}>
        <Heading size='xl' pb={2}>Vision</Heading>
        <Box w={{ base: "100%", lg: "75%" }} className="markdown-body">
          <DisplayContent content={v2mom.vision} emptyMessage="Add your vision" />
        </Box>
      </Box>
      <Box mb={12}>
        <Heading size='xl' pb={2}>Values</Heading>
        <Box w={{ base: "100%", lg: "75%" }} className="markdown-body">
          <DisplayContent content={v2mom.values} emptyMessage="Add your values" />
        </Box>
      </Box>
      <Box mb={12} pb={2}>
        <MethodsHeader mb={4} methodCount={v2mom.v2momMethods.length} />
        <MethodList methods={v2mom.v2momMethods} />
      </Box>
    </Box>
  );
}

export default V2momBuilder;