const useSession = () => {
  const setSession = (payload) => {
    localStorage.setItem('polaris-token', payload.token);
    localStorage.setItem('polaris-token-expires', payload.expires);
    return true
  }

  const removeSession = () => {
    localStorage.clear();
    return true;
  }

  const getSessionToken = () => {
    return localStorage.getItem('polaris-token');
  };

  const getSessionExpiration = () => {
    return localStorage.getItem('polaris-token-expires');
  }

  const getSession = () => {
    return [ localStorage.getItem('polaris-token'), localStorage.getItem('polaris-token-expires') ]
  }

  const sessionExists = () => {
    const [ token, expires ] = getSession();
    const notExpired = (expires * 1000) > Date.now();

    return token && notExpired;
  };

  return { sessionExists, getSession, getSessionToken, getSessionExpiration, removeSession, setSession };
}

export { useSession };