import {
  Box,
  Heading,
} from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Shell from '@components/common/Shell';
import V2momForm from './V2momForm';
import { GET_V2MOM } from '@queries/V2moms';


const EditDetailsV2mom = () => {
  const { id } = useParams();

  const { loading, error, data } = useQuery(GET_V2MOM, {
    variables: {
      id: id,
    },
  });

  if (loading) return <p>Loading ...</p>;
  if (error) return `${error}`;

  const initialValues = {
    id: data.v2mom.id,
    collectionId: data.v2mom.collection.id,
    name: data.v2mom.name,
    vision: data.v2mom.vision,
    values: data.v2mom.values,
  }

  return (
    <Shell>
      <Box>
        <Heading mb={8}>Edit {data.v2mom.name}</Heading>
        <V2momForm initialValues={initialValues} submitLabel='Update' />
      </Box>
    </Shell>
  );
}

export default EditDetailsV2mom;