import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useSession } from '@utils/Session';
import { useNavigate } from "react-router-dom";
import { useNotifier } from '@utils/Notify';

const GraphqlProvider = ({children}) => {
  const { removeSession, getSessionToken } = useSession();
  const navigate = useNavigate();
  const { warning } = useNotifier();

  const httpLink = new HttpLink({ uri: process.env.REACT_APP_POLARIS_API + '/graphql' });

  const addAuthHeader = setContext((_, { headers }) => {
    const token = getSessionToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });

  const handleUnauthorized = onError(({ networkError }) => {
    if (networkError && networkError.statusCode === 401) {
      networkError.message = 'You must be logged in.';
      removeSession();
      warning({
        title: 'Authentication error',
        description: 'You need to login again to continue',
      })
      navigate('/login');
    }
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
            addAuthHeader,
            handleUnauthorized,
            httpLink,
    ]),
    cache: new InMemoryCache()
  });

  return(
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
}

export { GraphqlProvider };