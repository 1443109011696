import { 
  Box,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import statusMap from '@components/common/StatusMap';

function statusAccumulator(methods) {
  return methods.reduce((accumulator, method) => {
    return method.measures.reduce((accumulator, measure) => {
      accumulator[measure.status] = (accumulator[measure.status] || 0) + 1;
      return accumulator;
    },accumulator);
  },{});
}

function statusPercentage(data) {
  const initialValue = 0;

  const totalMeasures = data.total || Object.values(data).reduce(
    (prev,curr) => prev + curr, initialValue
  );

  return Object.keys(data).reduce((accumulator, key) => {
    return {...accumulator, [key]: ((data[key]/totalMeasures*100).toFixed(1)+'%')};
  }, {});
}

const EmptyProgess = () => {
  return (
    <Box w='100%' h={1.5} bg='blackAlpha.200' borderRadius='full' />
  );
}

const Progress = ({v2mom, stats}) => {
  const statuses = stats || statusAccumulator(v2mom.v2momMethods);
  const { total, ...otherStats } = statuses;
  const percentage = statusPercentage(otherStats);

  if (Object.keys(statuses).length === 0) return <EmptyProgess />;

  const statusBar = Object.entries(percentage).map(([status,number], i) => {
    return (
      <Tooltip 
        hasArrow 
        key={i} 
        label={statuses[status]+' '+statusMap[status].sentence} 
        bg={statusMap[status].background} 
        color={statusMap[status].text} 
        borderRadius='full'>
          <Box w={number} bg={statusMap[status].background} />
      </Tooltip>
    );
  });

  return(
    <Flex h={1.5} width="100%" bgColor="blackAlpha.200">
      {statusBar}
    </Flex>
  );
}

export default Progress;