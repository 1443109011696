const inputStyle = {
  border: 0,
  borderRadius: 0,
  borderBottomWidth: '1px',
  borderBottomColor: 'green.600',
  borderBottomStyle: 'solid',
  bg: 'blackAlpha.50',
  _placeholder: {
    color: 'blackAlpha.400',
  },
  _hover: {
    paddingBottom: '-1px',
    bg: 'blackAlpha.100',
    boxShadow: '0 2px #2F855A'
  },
  _focus: {
    bg: 'blackAlpha.200',
    boxShadow: '0 2px #2F855A'
  }
};

export { inputStyle };