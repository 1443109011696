import { gql } from '@apollo/client';


const DELETE_METHOD = gql`
mutation DeleteMethod($id: ID!) {
  deleteV2momMethod (input: {
    id: $id
  }) {
    v2momMethod {
      id
    }
  }
}
`;

export default DELETE_METHOD;