import {
  Box,
  Flex,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { Link } from "react-router-dom";
import { useUserContext } from '@contexts/UserProvider';
import { useQuery } from '@apollo/client';
import { DASHBOARD } from '@queries/Users';
import Shell from '@components/common/Shell';
import Button from '@components/forms/Button';
import { ArrowIcon } from '@components/icons';
import linkHoverStyles from '@components/common/Styles';
import Loader from '@components/common/Loader';
import V2momCard from '@components/Dashboard/V2momCard';
import DueDates from '@components/Dashboard/DueDates';
import MethodList from '@components/Dashboard/MethodList';
import MeasureList from '@components/Dashboard/MeasureList';

const Dashboard = () => {
  const { state } = useUserContext();
  const { loading, data } = useQuery(DASHBOARD);

  if (loading) return <Loader />;

  let { v2moms, v2momMethods, measures } = data.currentUser;
  const currentV2moms = v2moms.filter(v2mom => v2mom.current === true);

  const V2momCards = () => {
    const Empty = () => {
      return (
        <Flex direction="column" p={4} bgColor="blackAlpha.50">
          <Box mb={8}>
            <Text mb={4} lineHeight='shorter' fontSize='3xl' fontWeight='light'>
              You don't have a V2MOM for the current year.
            </Text>
            <Text w='80%'>If this is your first V2MOM, you might want to read through the frequently asked questions. <Link to="/help"><Text as='span' fontWeight='bold' _hover={linkHoverStyles}>Help<ArrowIcon ml={1} /></Text></Link></Text>
          </Box>
          <Box>
            <Link to="/v2moms/new">
              <Button variant='primary'>Create V2MOM</Button>
            </Link>
          </Box>
        </Flex>
      );
    }

    if (currentV2moms.length === 0) return <Empty />;

    const cards = currentV2moms.map((v2mom) => (
      <V2momCard v2mom={v2mom} key={v2mom.id} />
    ));

    return cards;
  };

  const columns = currentV2moms.length > 1 ? 2 : 1

  return (
    <Shell>
      <Box mb={8}>
        <Text fontSize='xl' fontWeight='light' borderBottomWidth='1px' borderBottomStyle='solid' borderBottomColor='green.600'>
          Hello {state.user.name}
        </Text>
      </Box>
      <Flex mb={8} direction={{ base: 'column-reverse', lg: 'row' }}>
        <Box w={{ base: '100%', lg: '60%' }}>
          <SimpleGrid mr={4} columns={{ base: 1, lg: columns}} spacing={4}>
            <V2momCards />
          </SimpleGrid>
        </Box>
        <Box mb={4}>
          <DueDates />
        </Box>
      </Flex>
      <Box mb={8}>
        <Text mb={2} fontSize='3xl' fontWeight='light'>Methods assigned to you</Text>
        <Box mb={1}>
          <MethodList methods={v2momMethods} />
        </Box>
      </Box>
      <Box mb={8}>
        <Text mb={2} fontSize='3xl' lineHeight='shorter' fontWeight='light'>Measures assigned to you</Text>
        <Box mb={1}>
          <MeasureList measures={measures} />
        </Box>
      </Box>
    </Shell>
  );
}

export default Dashboard;