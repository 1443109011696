import { gql } from '@apollo/client';
import MEASURE_FRAGMENT from './FragmentMeasure';

const PRIORITIZE_MEASURE = gql`
${MEASURE_FRAGMENT}

mutation PrioritizeMeasure(
  $id:ID!
  $priority:Int!
) {
  prioritizeMeasure(input: {
    params: {
      id:$id,
      priority:$priority
    }
  }) {
    measure {
      ...CoreMeasureFields
      v2momMethodId
    }
  }
}
`;

export default PRIORITIZE_MEASURE;