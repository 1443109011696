import Shell from '@components/common/Shell';
import { Progress } from '@chakra-ui/react';

const Loader = () => {
  return (
    <Shell>
      <Progress colorScheme='green' size='xs' isIndeterminate />
    </Shell>
  );
}

export default Loader;