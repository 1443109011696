import { useDisclosure } from '@chakra-ui/react';
import { Button } from '@components/forms';
import NewMethodModal from './NewMethodModal';



const NewMethodButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} variant='primary' responsive>
        Add method
      </Button>
      <NewMethodModal isOpen={isOpen} onClose={onClose} action='Add' />
    </>
  );
}

export default NewMethodButton;