import { Box } from '@chakra-ui/react';
import { useV2momContext } from '@contexts/V2momProvider';
import MethodPlaceholder from './MethodPlaceholder';
import Method from './Method';


const MethodList = () => {
  const { state: v2momState } = useV2momContext();
  const { v2momMethods } = v2momState;

  if (v2momMethods.length === 0) return <MethodPlaceholder />;
  const methodList = v2momMethods.map((method) => <Method key={method.id} method={method} />)

  return (
    <Box>
      {methodList}
    </Box>
  );
}

export default MethodList;