import { gql } from '@apollo/client';

const LIST_COLLECTIONS = gql`
query ListCollections {
  collections {
    id
    name
    current
    startsAt
    endsAt
  }
}
`;

export default LIST_COLLECTIONS;