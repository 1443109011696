import { useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { LIST_V2MOM } from '@queries/V2moms';
import Shell from '@components/common/Shell';
import Loader from '@components/common/Loader';
import PageHeader from '@components/common/PageHeader';
import V2momTable from '@components/V2mom/V2momTable';
import { TextInput } from '@components/forms';
import { ArrowIcon } from '@components/icons';

const SearchV2mom = () => {
  const [query, setQuery] = useState("");
  const { loading, error, data } = useQuery(LIST_V2MOM);

  if (loading) return <Loader />;
  if (error) return `${error}`;

  const filterData = data.v2moms.filter((v2mom) => {
    if (query === "") {
      return v2mom;
    } else if (v2mom.name.toLowerCase().includes(query.toLowerCase())) {
      return v2mom;
    } else if (v2mom.owner.name.toLowerCase().includes(query.toLowerCase())) {
      return v2mom;
    }
    return null;
  });

  const EmptyResults = () => {
    return (
      <>
        <Text>No results found.</Text>
      </>

    );
  }

  const DisplayResults = () => {
    if (query === "") return <></>;
    if (filterData.length === 0 ) return <EmptyResults />

    return <V2momTable v2moms={filterData} />
  }

  return (
    <Shell>
      <Box>
        <PageHeader align='center'>Find a V2MOM</PageHeader>
        <TextInput
          label='Search by name or owner'
          id='search'
          onChange={event => setQuery(event.target.value)}
          placeholder='e.g., Patchy MacDougal or Special Project V2MOM'
        />
        <DisplayResults />
        <Box align='right'>
          <Link to='/v2moms'>
            <Flex
              display='inline'
              fontSize='sm'
              textTransform='uppercase'
              color='green.600'
              _hover={{
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderBottomColor: 'green.600'
              }}
            >
              View all V2MOMs
              <ArrowIcon w={3} h={3} ml={1} />
            </Flex>
          </Link>
        </Box>
      </Box>
    </Shell>
  );
}

export default SearchV2mom;