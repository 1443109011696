import { Box, Heading } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';


const Obstacles = ({obstacles}) => {
  const content = (obstacles === '' || obstacles === null) ? '_No obstacles identified_' : obstacles 
  
  return (
    <Box color='blackAlpha.700'>
      <Heading size='sm' mb={2}>Obstacles</Heading>
      <Box className="markdown-body" color='blackAlpha.700'>
        <ReactMarkdown children={content} remarkPlugins={[remarkGfm]} />
      </Box>
    </Box>
  );
}

export default Obstacles;