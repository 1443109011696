import {
  Center,
  Text,
} from '@chakra-ui/react';
import NewMethodButton from './NewMethodButton';


const MethodPlaceholder = () => {
  return (
    <Center p={8} mt={2} flexDirection='column' bg='blackAlpha.50' borderBottomWidth='1px' borderBottomStyle='sollid' borderBottomColor='green.600'>
      <Text fontWeight='bold' color='blackAlpha.900'>Add your first method</Text>
      <Text w="50%" mb={4} align="center" color='blackAlpha.700'>Methods are a collection of goals, projects, and work that'll help you achieve your vision</Text>
      <NewMethodButton />
    </Center>
  );
}

export default MethodPlaceholder;