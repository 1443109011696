import { Box, Flex, Spacer, Text } from '@chakra-ui/react';
import { Link } from "react-router-dom";
import { Progress } from '@components/V2mom';

const V2momCard = ({v2mom}) => {
  return (
    <Box key={v2mom.id} bgColor="blackAlpha.50">
      <Progress stats={v2mom.stats} />
      <Flex h='100%' p={4} direction='column' align='center'>
        <Link to={`/v2moms/${v2mom.id}`}>
          <Text fontSize='xl' fontWeight='light' lineHeight='short' _hover={{ borderBottomWidth: '1px', borderBottomStyle: 'solid', borderBottomColor: 'green.600' }} display='inline'>{v2mom.name}</Text>
        </Link>
        <Spacer />
        <Flex>
          <Box p={2}>
            <Text fontSize='8xl' lineHeight='1'>{((v2mom.stats.complete/v2mom.stats.total*100) || 0).toFixed(0)}%</Text>
            <Text align='center' textTransform='uppercase' fontSize='xs' color='blackAlpha.400'>Complete</Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

export default V2momCard;