import { Text } from '@chakra-ui/react';

const PageHeader = ({divider, children, ...props}) => {
  let borderStyles = {}

  if (divider) {
    borderStyles = {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'green.600'
    }
  }

  return (
    <Text pb={4} mb={8} fontSize='6xl' fontWeight='thin' { ...borderStyles } {...props}>
      {children}
    </Text>
  );
}

export default PageHeader;