import { Accordion, Box, Divider, Text } from '@chakra-ui/react';
import MeasureTableRow from './MeasureTableRow';
import AddMeasureButton from './AddMeasureButton';
import { useV2momContext } from '@contexts/V2momProvider';


const MeasureTable = ({ methodId }) => {
  const { state: v2mom } = useV2momContext();

  const measures = v2mom.v2momMethods.find(m => m.id === methodId).measures;
  const measureList = measures.map((measure, i) => {
    const first = (i === 0 ? true : false);
    const last = (i === measures.length-1 ? true : false);

    return <MeasureTableRow measure={measure} key={i} first={first} last={last} />
  });

  const EmptyList = () => {
    return (
      <>
        <Divider />
        <Box mt={1} p={2} bg='blackAlpha.100' borderRadius='sm' align='center'>
          <Text fontSize='sm'>No measures</Text>
        </Box>
      </>
    );
  }

  return (
    <>
      <Accordion allowMultiple>
        {measureList}
      </Accordion>
      {v2mom.edit ? 
        <AddMeasureButton methodId={methodId} />
      : measures.length === 0 ? <EmptyList /> : null }
    </>
  );
}

export default MeasureTable;