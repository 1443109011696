import { gql } from '@apollo/client';
import USER_FRAGMENT from './FragmentUser';

const CURRENT_USER = gql`
${USER_FRAGMENT}

query CurrentUser {
  currentUser {
    ...CoreUserFields
    v2moms {
      id
      name
      current
      collection {
        name
      }
    }
  }
}
`;

export default CURRENT_USER;