import { useOktaAuth } from "@okta/okta-react";

const LoginButton = ({As, text}) => {
  const { oktaAuth } = useOktaAuth();

  const loginWithRedirect = () => {
    oktaAuth.signInWithRedirect({ originalUri: "/authenticate" });
  }

  return (
    <As variant='primary' onClick={loginWithRedirect}>
      {text}
    </As>
  );
}

export default LoginButton;