import {
  FormControl,
  Stack,
} from '@chakra-ui/react';
import { Button, Select, TextInput, Textarea } from '@components/forms';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_USERS } from '@queries/Users';
import { ADD_METHOD } from '@queries/V2moms';
import { useV2momContext } from '@contexts/V2momProvider';
import { useNotifier } from '@utils/Notify';


const MethodForm = ({dispatchType, closeModal, submitLabel, initialValues}) => {
  const { formSubmissionError, serverError } = useNotifier();
  const { state, dispatch } = useV2momContext();

  initialValues.v2momId = state.id

  const [addMethod, { loading }] = useMutation(ADD_METHOD,{
    onCompleted: (data) => {
      dispatch({ type: dispatchType, payload: { method: data.addV2momMethod.v2momMethod } })
      closeModal();
    },
    onError: (error) => {
      serverError(error.message)
    },
  });

  function validate(values) {
    const errors = {};
    if (!values.name) {
      errors.name = 'Method name is required';
    }

    return errors;
  }

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: initialValues,
    validate,
    onSubmit: values => {
      addMethod({ variables: {
        id: values.id,
        v2momId: values.v2momId,
        name: values.name,
        priority: parseInt(values.priority),
        ownerId: values.ownerId,
        obstacles: values.obstacles,
      }});
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (Object.keys(formik.errors).length !== 0) {
      formSubmissionError();
    }

    formik.handleSubmit();
  }

  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(GET_ALL_USERS, {});

  if (usersLoading) return <p>Loading ...</p>;
  if (usersError) return `${usersError}`;

  const userSelect = usersData.users.map(({ id, name }) => (
    <option key={id} value={id}>{name}</option>
  ));

  return (
    <form onSubmit={handleSubmit}>
      <FormControl>
        <TextInput
          label='Method Name'
          id='name'
          value={formik.values.name}
          error={formik.touched.name && formik.errors.name ? formik.errors.name : null}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder='e.g., Build a best-in-class team'
          required
        />
        <Select
          label='Owner'
          id='ownerId'
          value={formik.values.ownerId ?? ''}
          error={formik.touched.ownerId && formik.errors.ownerId ? formik.errors.ownerId : null}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder='None'
        >
          {userSelect}
        </Select>
        <Textarea
          label='Obstacles'
          id='obstacles'
          value={formik.values.obstacles}
          error={formik.touched.obstacles && formik.errors.obstacles ? formik.errors.obstacles : null}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder='e.g., Increase competition in the mid-market'
          markdown
        />
        <Stack direction='row' spacing={2} my={4} justifyContent='right'>
          <Button variant='ghost' onClick={closeModal}>Close</Button>
          <Button variant='primary' type='submit' isLoading={loading}>{submitLabel}</Button>
        </Stack>
      </FormControl>
    </form>
  );
}

export default MethodForm;