import { gql } from '@apollo/client';
import METHOD_FRAGMENT from './FragmentMethod';

const PRIORITIZE_METHOD = gql`
${METHOD_FRAGMENT}

mutation PrioritizeMethod(
  $id: ID!
  $priority: Int!
) {
  prioritizeV2momMethod(input: {
    params: {
      id: $id
      priority: $priority,
    }
  }) {
    v2momMethod {
      ...CoreMethodFields
    }
  }
}
`;

export default PRIORITIZE_METHOD;