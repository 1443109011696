import { Badge, Box, Flex, HStack, Progress, Text } from '@chakra-ui/react';
import { Link } from "react-router-dom";
import { Datetime } from '@components/common/Datetime';
import { YearBadge } from '@components/V2mom';

const V2momTableRow = ({v2mom}) => {
  return(
    <Link to={'/v2moms/'+v2mom.id}>
      <Box mb={1} py={2} borderBottomWidth='1px' borderBottomColor='rgba(0,0,0,0)' _hover={{ bgColor: 'blackAlpha.50', borderBottomWidth: '1px', borderBottomStyle: 'solid', borderBottomColor: 'green.600' }}>
        <HStack>
          <Box w="40%" pl={2}>
              {v2mom.name}
          </Box>
          <Box w="10%"><YearBadge name={v2mom.year} current={v2mom.current} /></Box>
          <Box w="20%">{v2mom.owner}</Box>
          <Box w="15%">
            <Flex justifyContent="center" align="center">
              <Text fontSize='sm' pr={2}>{v2mom.complete}%</Text>
              <Progress mt={1} w='80%' colorScheme='teal' size='sm' value={v2mom.complete}  borderRadius='full' bg='blackAlpha.300' mb={1} />
            </Flex>
          </Box>
          <Box w="15%" align="right" pr={2}><Datetime timestamp={v2mom.updatedAt} format="date" /></Box>
        </HStack>
      </Box>
    </Link>

  );
}

export default V2momTableRow;