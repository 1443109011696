import { gql } from '@apollo/client';
import MEASURE_FRAGMENT from './FragmentMeasure';


const GET_MEASURE = gql`
${MEASURE_FRAGMENT}

query GetMeasure($id: ID!) {
  measure (id: $id) {
    ...CoreMeasureFields
    v2momMethodId
  }
}
`;

export default GET_MEASURE;