import { useEffect, useState } from 'react';
import { Avatar, Box, Flex, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Stack, useBreakpointValue } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons'
import LogoutButton from './LogoutButton';
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { useUserState } from '@contexts/UserProvider';
import { ArrowIcon, SearchIcon } from '@components/icons';
import { YearBadge } from '@components/V2mom';
import linkHoverStyles from './Styles';

const CurrentUserNavigation = () => {
  const { user } = useUserState();
  const menu = useBreakpointValue({ base: <MobileMenu v2moms={user.v2moms} />, lg: <DefaultMenu user={user} />})

  return (
    <>
      {menu}
    </>
  );
}

const DefaultMenu = ({user}) => {
  return (
    <Stack zIndex={9999} direction='row' spacing={8} align='center'>
      <NavLink to="/v2moms/search"><SearchIcon w={5} h={5} /></NavLink>
      <MyV2momMenu v2moms={user.v2moms} />
      <NavLink to="/dashboard">Dashboard</NavLink>
      <Box>
        <Menu>
          <MenuButton>
            <Avatar size='sm' name={user.name} src='' />
          </MenuButton>
          <MenuList p={0} boxShadow={0} rounded='none' bgColor='#f5f5f6' border={0} borderBottomWidth={1} borderBottomStyle='solid' borderBottomColor='green.600'>
            <Link to='/v2moms/new'>
              <MenuItem _focus={{ bgColor: 'blackAlpha.100' }} _active={{ bgColor: 'green.50' }}>
                Create V2MOM
              </MenuItem>
            </Link>
            <LogoutButton As={MenuItem} _focus={{ bgColor: 'blackAlpha.100' }} />
          </MenuList>
        </Menu>
      </Box>
    </Stack>
  );
}

const MobileMenu = ({v2moms}) => {
  const CreateV2mom = () => {
    return (
      <Link to='/v2moms/new'>
        <MenuItem _focus={{ bgColor: 'blackAlpha.100' }} _active={{ bgColor: 'green.50' }}>
          Create V2MOM
        </MenuItem>
      </Link>
    );
  }

  const myV2momList = v2moms.map(({id, name}) => (
    <Link key={id} to={`/v2moms/${id}`}>
      <MenuItem _focus={{ bgColor: 'blackAlpha.100' }} _active={{ bgColor: 'green.50' }}>
        {name}
      </MenuItem>
    </Link>
  ));

  return (
    <Flex display={{ base: 'block', lg: 'none' }}>
      <Menu>
        <MenuButton>
          <HamburgerIcon w={5} h={5} />
        </MenuButton>
        <MenuList pt={2} boxShadow={0} rounded='none' bgColor='#f5f5f6' border={0} borderBottomWidth={1} borderBottomStyle='solid' borderBottomColor='green.600'>
          <MenuGroup mx={0} px={3} title='My V2MOMs'>
            { v2moms.length === 0 ? <CreateV2mom /> : myV2momList }
          </MenuGroup>
          <MenuDivider />
          <Link to='/dashboard'>
            <MenuItem px={4}>Dashboard</MenuItem>
          </Link>
          <Link to='/v2moms/search'>
            <MenuItem px={4}>Search</MenuItem>
          </Link>
          <MenuDivider />
          <LogoutButton As={MenuItem} px={4} _focus={{ bgColor: 'blackAlpha.100' }} />
        </MenuList>
      </Menu>
    </Flex>
  );
}

const MyV2momMenu = ({v2moms}) => {
  const [current, setCurrent] = useState([]);
  const [past, setPast] = useState([]);

  useEffect(() => {
    setCurrent(v2moms.filter(v2mom => v2mom.current === true));
    setPast(v2moms.filter(v2mom => v2mom.current === false));
  },[]);

  const currentList = current.map(({id, name, collection}) => (
    <Link key={id} to={`/v2moms/${id}`}>
      <MenuItem _focus={{ bgColor: 'blackAlpha.100' }} _active={{ bgColor: 'green.50' }}>
        <YearBadge name={collection.name} current={true} />
        {name}
      </MenuItem>
    </Link>
  ));

  const pastList = past.map(({id, name, collection}) => (
    <Link key={id} to={`/v2moms/${id}`}>
      <MenuItem color='gray.600' _focus={{ bgColor: 'blackAlpha.100' }} _active={{ bgColor: 'green.50' }}>
        <YearBadge name={collection.name} />
        {name}
      </MenuItem>
    </Link>
  ));

  if (v2moms.length === 0) {
    return <NavLink to="/v2moms/new">Create V2MOM</NavLink>
  }

  if (v2moms.length === 1) {
    const [v2mom] = v2moms;
    return <NavLink to={`/v2moms/${v2mom.id}`}>My V2MOM</NavLink>;
  }

  return(
    <Box>
      <Menu>
        <MenuButton _hover={linkHoverStyles}>
          <Box>
            <Flex align='center'>
              My V2MOMs
            </Flex>
          </Box>
        </MenuButton>
        <MenuList p={0} boxShadow={0} rounded='none' bgColor='#f5f5f5' border={0} borderBottomWidth={1} borderBottomStyle='solid' borderBottomColor='green.600'>
            {currentList}
          <MenuDivider />
          <MenuGroup title='Past' marginInlineStart={3} color='gray.500'>
            {pastList}
          </MenuGroup>
        </MenuList>
      </Menu>
    </Box>
  );
}

const NavLink = ({ children, to, ...props }) => {
  const resolved = useResolvedPath(to)
  const isActivePage = useMatch({ path: resolved.pathname, end: true})

  return (
    <Box {...props}>
      <Link to={to}>
        <Flex align='center' _hover={linkHoverStyles}>
          {isActivePage && <ActiveIndicator />}
          {children}
        </Flex>
      </Link>
    </Box>
  );
}

const ActiveIndicator = () => {
  return <ArrowIcon color="currentColor" w={5} h={5} mr={2} />;
}

export default CurrentUserNavigation;