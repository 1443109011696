import { gql } from '@apollo/client';
import MEASURE_FRAGMENT from './FragmentMeasure';
import UPDATE_FRAGMENT from './FragmentUpdate';


const ADD_MEASURE = gql`
${MEASURE_FRAGMENT}
${UPDATE_FRAGMENT}

mutation AddMeasure(
  $id:ID
  $methodId:ID!
  $name:String!
  $priority:Int!
  $ownerId:ID
  $measureType:String!
  $target:Float
) {
  addMeasure(input: {
    params: {
      id:$id,
      methodId:$methodId,
      name:$name,
      priority:$priority
      ownerId:$ownerId,
      measureType:$measureType,
      target:$target
    }
  }) {
    measure {
      ...CoreMeasureFields
      v2momMethodId
      updates {
        ...CoreUpdateFields
      }
    }
  }
}
`;

export default ADD_MEASURE;