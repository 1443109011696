import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/react';
import MethodForm from './MethodForm';
import { useV2momContext } from '@contexts/V2momProvider';


const NewMethodModal = ({isOpen, onClose, action}) => {
  const dispatchType = 'ADD_METHOD';
  const { state } = useV2momContext();
  const nextPriority = state.v2momMethods.length + 1;

  const initialValues = {
    id: null,
    v2momId: state.id,
    name: '',
    priority: nextPriority,
    ownerId: null,
    obstacles: '',
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl' motionPreset='slideInBottom' isCentered>
      <ModalOverlay />
      <ModalContent borderRadius='sm'>
        <ModalHeader>Add method</ModalHeader>
        <ModalBody>
          <MethodForm initialValues={initialValues} dispatchType={dispatchType} closeModal={onClose} submitLabel='Add' />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default NewMethodModal;