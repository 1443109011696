import {
  Flex,
  Text,
  Spacer,
} from '@chakra-ui/react';
import { Datetime } from '@components/common/Datetime';
import { useV2momContext } from '@contexts/V2momProvider';

const V2momHeader = () => {
  const { state } = useV2momContext();

  return (
    <Flex mb={2} direction={{ base: 'column', lg: 'row' }}>
      <Text fontSize='4xl' fontWeight='bold' lineHeight='1.2' w='70%'>{state.name}</Text>
      <Spacer />
      <Flex direction='column'>
        <Text fontSize={{ base: 'md', lg: '4xl' }} fontWeight='bold' lineHeight='1.2' align={{ lg: 'right' }}>{state.collection.name}</Text>
        <Text fontSize={{ base: 'md', lg: 'sm' }} color='blackAlpha.600' align={{ lg: 'right' }}>{state.owner.name}</Text>
        <Text fontSize={{ base: 'md', lg: 'sm' }} color='blackAlpha.600' align={{ lg: 'right' }}>Last Updated <Datetime timestamp={state.updatedAt} format='dateFull' /></Text>
      </Flex>
    </Flex>
  );
}

export default V2momHeader;