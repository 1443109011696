import {
  FormControl,
  Stack,
} from '@chakra-ui/react';
import { Button, Select, TextInput, Textarea } from '@components/forms';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { ADD_UPDATE } from '@queries/V2moms';
import { useV2momContext } from '@contexts/V2momProvider';
import statusMap from '@components/common/StatusMap';
import { useNotifier } from '@utils/Notify';


const UpdateForm = ({type, dispatchType, closeModal, submitLabel, initialValues}) => {
  const { formSubmissionError, serverError } = useNotifier();
  const { state, dispatch } = useV2momContext();

  initialValues.v2momId = state.id

  const [addUpdate, { loading }] = useMutation(ADD_UPDATE,{
    onCompleted: (data) => {
      dispatch({ type: dispatchType, payload: { update: data.addUpdate.update } })
      closeModal();
    },
    onError: (error) => {
      serverError(error.message);
    },
  });

  function validate(values) {
    const errors = {};
    if (!values.status) {
      errors.status = 'Status is required';
    }
    if (isNaN(values.current) && type === 'progress') {
      errors.current = 'Current progress is required'
    }
    if (!values.comment) {
      errors.comment = 'Comment is required'
    }

    return errors;
  }

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: initialValues,
    validate,
    onSubmit: values => {
      addUpdate({ variables: {
        id: values.id,
        measureId: values.measureId,
        status: values.status,
        current: values.current || 0,
        comment: values.comment,
      }});
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (Object.keys(formik.errors).length !== 0) {
      formSubmissionError();
    }

    formik.handleSubmit();
  }

  const { total, ...otherStatus } = statusMap;
  const statusSelect = Object.entries(otherStatus).map((entry,index) => (
    <option key={index} value={entry[0]}>{entry[1].title}</option>
  ));

  return (
    <form onSubmit={handleSubmit}>
      <FormControl>
        <Select
          label='Status'
          id='status'
          value={formik.values.status ?? ''}
          error={formik.touched.status && formik.errors.status ? formik.errors.status : null}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder='Select'
          required
        >
          {statusSelect}
        </Select>
        {type === 'progress' ?
            <TextInput
            label='Current Progress'
            id='current'
            type='number'
            value={formik.values.current}
            error={formik.touched.current && formik.errors.current ? formik.errors.current : null}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder='e.g., 42'
            required
          />
        : null }
        <Textarea
          label='Comment'
          id='comment'
          value={formik.values.comment}
          error={formik.touched.comment && formik.errors.comment ? formik.errors.comment : null}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder='e.g., Completed CultureAmp survey, Firehose 2022'
          required
          markdown
        />
        <Stack direction='row' spacing={2} my={4} justifyContent='right'>
          <Button variant='ghost' onClick={closeModal}>Close</Button>
          <Button variant='primary' type='submit' isLoading={loading}>{submitLabel}</Button>
        </Stack>
      </FormControl>
    </form>
  );
}

export default UpdateForm;