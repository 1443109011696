import { Box } from '@chakra-ui/react';
import Shell from '@components/common/Shell';
import PageHeader from '@components/common/PageHeader';
import Section from '@components/Help/Section';
import { data } from '@components/Help/data'

const Help = () => {
  const faq = Object.entries(data).map(([key,section]) => {
    return <Section key={key} section={section} />
  });

  return (
    <Shell>
      <Box>
        <PageHeader divider>Help</PageHeader>
        {faq}
      </Box>
    </Shell>
  );
}

export default Help;