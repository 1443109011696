import { DateTime, Duration } from "luxon";
import { useEffect, useState } from 'react';

const Datetime = ({timestamp, format}) => {
  const formats = {
    'date': DateTime.DATE_MED,
    'dateFull': DateTime.DATE_FULL,
    'datetime': DateTime.DATETIME_MED,
  }

  const dt = DateTime.fromISO(timestamp);
  const display = dt.toLocaleString(formats[format]);

  return (
    <>{display}</>
  );
}

const Countdown = ({expires}) => {
  const [ remaining, setRemaining ] = useState((expires*1000)-Date.now());

  useEffect(() => {
    if (!remaining) return;

    const tick = setInterval(() => {
      setRemaining(remaining - 1000);
    }, 1000);
    return () => clearInterval(tick);
  }, [remaining]);

  return (
    <>{Duration.fromMillis(remaining).toFormat("m:ss")}</>
  );
}

export { Countdown, Datetime };