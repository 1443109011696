import { gql } from '@apollo/client';

const MEASURE_FRAGMENT = gql`
  fragment CoreMeasureFields on Measure {
    id
    name
    priority
    measureType
    target
    status
    current
    owner {
      id
      name
    }
    updatedAt
  }
`;

export default MEASURE_FRAGMENT;