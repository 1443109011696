import {
  Box,
  Flex,
  HStack,
  Progress as ChakraProgress,
  Spacer,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Link } from "react-router-dom";
import { Progress } from '@components/V2mom';
import Button from '@components/forms/Button';
import linkHoverStyles from '@components/common/Styles';

const MethodRow = ({method}) => {
  const completePercent = ((method.stats.complete/method.stats.total*100) || 0).toFixed(0);

  const MobileRow = () => {
    return (
      <Box
        key={method.id}
        py={4}
        borderBottomWidth='1px'
        borderBottomStyle='solid'
        borderBottomColor='blackAlpha.200'
      >
        <Box mb={2}>
          <Link to={`/v2moms/${method.v2mom.id}`}>
            <Text fontSize='xs' color='blackAlpha.600' display='inline-block' _hover={linkHoverStyles}>
              {method.v2mom.name}
            </Text>
          </Link>
          <Text>M{method.priority}: {method.name}</Text>
        </Box>
        <Flex align='center'>
          <Box w='30%'>
            <Text fontSize='sm'>{completePercent}% complete</Text>
            <ChakraProgress mt={1} colorScheme='teal' size='sm' value={completePercent} borderRadius='full' bg='blackAlpha.300' mb={1} />
          </Box>
          <Spacer />
          <Box fontSize='sm' align='right'>
            <Link to={`/v2moms/${method.v2mom.id}/update`}>
              <Button size='sm' variant='ghost'>Update</Button>
            </Link>
          </Box>
        </Flex>
      </Box>
    );
  }

  const FullRow = () => {
    return (
      <HStack
        key={method.id}
        py={4}
        borderBottomWidth='1px'
        borderBottomStyle='solid'
        borderBottomColor='blackAlpha.200'
      >
        <Box w="40%" fontSize='sm'>
          <Link to={`/v2moms/${method.v2mom.id}`}>
            <Text display='inline-block' _hover={linkHoverStyles}>{method.v2mom.name} ({method.v2mom.owner.name})</Text>
          </Link>
        </Box>
        <Box w="30%" fontSize='sm'>M{method.priority}: {method.name}</Box>
        <Box w="20%" fontSize='sm'>
          <Progress stats={method.stats} />
          <Text mt={1}>{((method.stats.complete/method.stats.total*100) || 0).toFixed(0)}% complete</Text>
        </Box>
        <Box w="10%" fontSize='sm' align='right'>
          <Link to={`/v2moms/${method.v2mom.id}/update`}>
            <Button size='xs' variant='ghost'>Update</Button>
          </Link>
        </Box>
      </HStack>
    );
  }

  const responsive = useBreakpointValue({ base: <MobileRow />, lg: <FullRow /> });

  return (
    <>{responsive}</>
  );
}

export default MethodRow;