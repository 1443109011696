import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/react';
import MeasureForm from './MeasureForm';
import { useV2momContext } from '@contexts/V2momProvider';


const NewMeasuerModal = ({methodId, isOpen, onClose}) => {
  const dispatchType = 'ADD_MEASURE';
  const { state } = useV2momContext();
  const nextPriority = state.v2momMethods.find(method => method.id === methodId).measures.length + 1;

  const initialValues = {
    id: null,
    methodId: methodId,
    priority: nextPriority,
    name: '',
    ownerId: null,
    measureType: 'completion',
    target: 0,
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl' motionPreset='slideInBottom' isCentered>
      <ModalOverlay />
      <ModalContent borderRadius='sm'>
        <ModalHeader>Add measure</ModalHeader>
        <ModalBody>
          <MeasureForm initialValues={initialValues} dispatchType={dispatchType} closeModal={onClose} submitLabel='Add' />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default NewMeasuerModal;