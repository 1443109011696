import { Flex, Text } from '@chakra-ui/react';
import Shell from '@components/common/Shell';
import { Link } from 'react-router-dom';
import { useAuthState } from '@contexts/AuthProvider';
import { ArrowIcon } from '@components/icons';
import { Button } from '@components/forms';

const Error404 = () => {
  const { isAuthenticated } = useAuthState();

  return (
    <Shell>
      <Text mb={4} fontSize='6xl' fontWeight='thin' textAlign='center'>
        Couldn't find that page.
      </Text>
      <Flex justifyContent='center'>
        { isAuthenticated ? 
          <Link to="/dashboard">
            <Button variant='ghost'>
              <Text>Go to your dashboard</Text>
              <ArrowIcon ml={1} />
            </Button>
          </Link>
        : 
          <Link to="/">
            <Button variant='ghost'>
              <Text>Go back home</Text>
              <ArrowIcon ml={1} />
            </Button>
          </Link>
        }
      </Flex>

    </Shell>
  );
}

export default Error404;