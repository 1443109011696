import { gql } from '@apollo/client';
import METHOD_FRAGMENT from './FragmentMethod';
import MEASURE_FRAGMENT from './FragmentMeasure';
import UPDATE_FRAGMENT from './FragmentUpdate';


const ADD_METHOD = gql`
${METHOD_FRAGMENT}
${MEASURE_FRAGMENT}
${UPDATE_FRAGMENT}

mutation AddMethod(
  $id: ID
  $v2momId: ID!
  $name: String!
  $priority: Int!
  $ownerId: ID
  $obstacles: String
) {
  addV2momMethod(input: {
    params: {
      id: $id
      v2momId: $v2momId,
      name: $name,
      priority: $priority,
      ownerId: $ownerId,
      obstacles: $obstacles,
    }
  }) {
    v2momMethod {
      ...CoreMethodFields
      measures {
        ...CoreMeasureFields
        updates {
          ...CoreUpdateFields
        }
      }
    }
  }
}
`;

export default ADD_METHOD;