import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { Button } from '@components/forms';
import MethodForm from './MethodForm';
import { useQuery } from '@apollo/client';
import { GET_METHOD } from '@queries/V2moms';
import DeleteMethodModal from './DeleteMethodModal';

const EditMethodModal = ({isOpen, onClose, methodId}) => {
  const deleteModal = useDisclosure();
  const dispatchType = 'UPDATE_METHOD';

  const { loading, error, data } = useQuery(GET_METHOD, {
    variables: {
      id: methodId,
    },
  });

  if (loading) return <p>Loading ...</p>;
  if (error) return `${error}`;

  const initialValues = {
    id: data.v2momMethod.id,
    name: data.v2momMethod.name,
    priority: data.v2momMethod.priority,
    ownerId: data.v2momMethod.owner?.id,
    obstacles: data.v2momMethod.obstacles,
  };

  const confirmDelete = () => {
    deleteModal.onOpen();
  };

  return (
    <>
      <Modal autoFocus={false} isOpen={isOpen} onClose={onClose} size='2xl' motionPreset='slideInBottom' isCentered>
        <ModalOverlay />
        <ModalContent borderRadius='sm'>
          <ModalHeader as={Flex}>
            Edit method
            <Spacer />
            <Button variant='dangerGhost' size='sm' onClick={confirmDelete}>Delete</Button>
          </ModalHeader>
          <ModalBody>
            <MethodForm initialValues={initialValues} dispatchType={dispatchType} closeModal={onClose} submitLabel='Update' />
          </ModalBody>
        </ModalContent>
      </Modal>
      <DeleteMethodModal method={initialValues} modalDisclosure={deleteModal} />
    </>


  );
}

export default EditMethodModal;