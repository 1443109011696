import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_V2MOM } from '@queries/V2moms';
import Shell from '@components/common/Shell';
import { V2momBuilder } from '@components/V2mom';
import { V2momProvider } from '@contexts/V2momProvider';
import Loader from '@components/common/Loader';


const EditV2mom = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_V2MOM, {
    fetchPolicy: 'no-cache',
    variables: {
      id: id,
    },
  });

  if (loading) return <Loader />;
  if (error) return `${error}`;

  data.v2mom.edit = true

  return (
    <Shell>
      <V2momProvider initialState={data.v2mom}>
        <V2momBuilder />
      </V2momProvider>
    </Shell>
  );
}

export default EditV2mom;