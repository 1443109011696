import { 
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  ButtonGroup,
  Circle,
  Flex,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Spacer,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { CommentIcon } from '@components/icons';
import { Button } from '@components/forms';
import EditMeasuerModal from './EditMeasureModal';
import NewUpdateModal from '../Updates/NewUpdateModal';
import MeasureUpdate from './MeasureUpdate';
import statusMap from '@components/common/StatusMap';
import StatusDisplay from './StatusDisplay';
import { Datetime } from '@components/common/Datetime';
import { useV2momContext } from '@contexts/V2momProvider';
import { useMutation } from '@apollo/client';
import { PRIORITIZE_MEASURE } from '@queries/V2moms';
import { ArrowDownIcon, ArrowUpIcon } from '@components/icons';
import { useNotifier } from '@utils/Notify';

const MeasureTableRow = ({measure, first, last}) => {
  const { state: v2mom, dispatch } = useV2momContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const updateModal = useDisclosure();
  const { serverError } = useNotifier();

  const displayName = measure.owner ? measure.owner.name : 'None';

  const updates = measure.updates.map((update, i) => {
    return <MeasureUpdate update={update} measure={measure} key={i} />
  });

  const [changePriority] = useMutation(PRIORITIZE_MEASURE,{
    onCompleted: (data) => {
      dispatch({ type: 'UPDATE_MEASURE', payload: { measure: data.prioritizeMeasure.measure } })
    },
    onError: (error) => {
      serverError(error.message)
    },
  });

  const increasePriority = () => {
    changePriority({ variables: {
      id: measure.id,
      priority: measure.priority-1,
    }});
  }

  const decreasePriority = () => {
    changePriority({ variables: {
      id: measure.id,
      priority: measure.priority+1,
    }});
  }

  const MobileRow = () => {
    const displayName = measure.owner ? measure.owner.name : 'No owner';

    const updates = measure.updates.map((update, i) => {
      return <MeasureUpdate update={update} measure={measure} key={i} />
    });

    return (
      <AccordionItem py={2} display={{ base: 'block', lg: 'none'}}>
        <Text mb={1}>{measure.name}</Text>
        <Text mb={1} fontSize='xs'>{displayName}</Text>
        <Flex align='center'>
          <Circle size={3} bg={statusMap[measure.status].background} mr={1.5} />
          <Text fontSize='xs'>{statusMap[measure.status].title} as of <Datetime timestamp={measure.updatedAt} format='date' /></Text>
          <Spacer />
          { measure.updates.length !== 0 ?
            <AccordionButton as={Circle} w={6} h={6} _hover={{bgColor: 'blackAlpha.100'}}>
              <Flex align='center' justifyContent='right'>
                <Text fontSize='xs'>{measure.updates.length || 0}</Text>
                <CommentIcon ml={1} w={3} h={3} />
              </Flex>
            </AccordionButton>
          :
            null
          }
          { v2mom.edit ?
            <Box align='right'>
              <Button variant='ghost' onClick={updateModal.onOpen} fontSize='xs' borderRadius='full' px={2} py={1} h='auto'>Add update</Button>
              <NewUpdateModal measure={measure} isOpen={updateModal.isOpen} onClose={updateModal.onClose} />
            </Box>
          :
            null
          }
        </Flex>
        <AccordionPanel pb={4} px={0}>
          <VStack align='stretch'>
            {updates}
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    );
  }

  const FullRow = () => {
    return (
      <AccordionItem py={2} display={{ base: 'none', lg: 'block'}}>
        <HStack>
          <Box w="40%">
            { v2mom.edit ?
              <>
                <Popover trigger='hover' placement='left' openDelay='0' closeDelay='150'>
                  <PopoverTrigger>
                    <Text>{measure.name}</Text>
                  </PopoverTrigger>
                  <PopoverContent borderRadius='sm' boxShadow='none' w='min-content' border='none'>
                    <HStack>
                      <Button onClick={onOpen} fontSize='xs' borderRadius='full' px={3} py={1} h='auto'>Edit</Button>
                      <ButtonGroup isAttached>
                        { !first ? <Button onClick={increasePriority} fontSize='xs' borderRadius='full' p={0} py={1} h='auto'><ArrowUpIcon /></Button> : null }
                        { !last ? <Button onClick={decreasePriority} fontSize='xs' borderRadius='full' p={0} py={1} h='auto'><ArrowDownIcon /></Button> : null }
                      </ButtonGroup>
                    </HStack>
                  </PopoverContent>
                </Popover>
                <NewUpdateModal measure={measure} isOpen={updateModal.isOpen} onClose={updateModal.onClose} />
                <EditMeasuerModal measureId={measure.id} isOpen={isOpen} onClose={onClose} />
              </>
            : <Text>{measure.name}</Text> }
          </Box>
          <Box w="20%" content='center'>
            <StatusDisplay type={measure.measureType} status={measure.status} complete={false} target={measure.target} current={measure.current} />
          </Box>
          <Box w="20%">{displayName}</Box>
          <Box w="15%"><Datetime timestamp={measure.updatedAt} format="date" /></Box>
          <Box w="5%">
            { v2mom.edit ?
              <Popover trigger='hover' placement='right' openDelay='0' closeDelay='150'>
                <PopoverTrigger>
                  { measure.updates.length === 0 ? 
                    <Circle w={10} h={10}>
                      <Flex align='center' justifyContent='right'>
                        <Text fontSize='sm'>{measure.updates.length || 0}</Text>
                        <CommentIcon ml={1} w={4} h={4} />
                      </Flex>
                    </Circle>
                  :
                    <AccordionButton as={Circle} w={10} h={10} _hover={{bgColor: 'blackAlpha.100'}}>
                      <Flex align='center' justifyContent='right'>
                        <Text fontSize='sm'>{measure.updates.length || 0}</Text>
                        <CommentIcon ml={1} w={4} h={4} />
                      </Flex>
                    </AccordionButton>
                  }
                </PopoverTrigger>
                <PopoverContent borderRadius='sm' boxShadow='none' w='min-content' border='none'>
                  <Button onClick={updateModal.onOpen} fontSize='xs' borderRadius='full' px={2} py={1} h='auto'>Add update</Button>
                </PopoverContent>
              </Popover>
            :
              <Flex>
                { measure.updates.length === 0 ?
                  <Circle w={10} h={10} />
                :
                <AccordionButton as={Circle} w={10} h={10} >
                  <Flex align='center' justifyContent='right'>
                    <Text fontSize='sm'>{measure.updates.length || 0}</Text>
                    <CommentIcon ml={1} w={4} h={4} />
                  </Flex>
                </AccordionButton>
                }
              </Flex>
            }
          </Box>
        </HStack>
        <AccordionPanel pb={4} px={0}>
          <VStack align='stretch'>
            {updates}
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    );
  }

  const responsive = useBreakpointValue({ base: <MobileRow />, lg: <FullRow /> });

  return (
    <>
      {responsive}
    </>

  );
}

export default MeasureTableRow;