import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import UpdateForm from './UpdateForm';
import { Button } from '@components/forms';
import { GET_UPDATE } from '@queries/V2moms';
import DeleteUpdateModal from './DeleteUpdateModal';

const EditUpdateModal = ({update, measure, isOpen, onClose}) => {
  const dispatchType = 'UPDATE_UPDATE';
  const deleteModal = useDisclosure();

  const { loading, error, data } = useQuery(GET_UPDATE, {
    variables: {
      id: update.id,
    },
  });

  if (loading) return <p>Loading ...</p>;
  if (error) return `${error}`;

  const initialValues = {
    id: data.update.id,
    measureId: data.update.measureId,
    status: data.update.status,
    current: data.update.current,
    comment: data.update.comment,
  }

  const confirmDelete = () => {
    deleteModal.onOpen();
  };

  return (
    <>
      <Modal autoFocus={false} isOpen={isOpen} onClose={onClose} size='2xl' motionPreset='slideInBottom' isCentered>
        <ModalOverlay />
        <ModalContent borderRadius='sm'>
          <ModalHeader as={Flex}>
            <Box>
              <Text textTransform='uppercase' fontSize='sm' color='blackAlpha.600' lineHeight='short'>Edit update</Text>
              <Text pr='4'>{measure.name}</Text>
            </Box>
            <Spacer />
            <Button variant='dangerGhost' size='sm' onClick={confirmDelete}>Delete</Button>
          </ModalHeader>
          <ModalBody>
            <UpdateForm type={measure.measureType} initialValues={initialValues} dispatchType={dispatchType} closeModal={onClose} submitLabel='Edit' />
          </ModalBody>
        </ModalContent>
      </Modal>
      <DeleteUpdateModal update={initialValues} modalDisclosure={deleteModal} />
    </>

  );
}

export default EditUpdateModal;