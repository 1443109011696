export const WhatMakesAGoodMeasure = {
  question: "How do I write a good measure?",
  answer: `
Writing good measures is essential to using the V2MOM day-to-day in setting priorities and identifying success. When writing your measures keep the acronym _SMART_ in mind:
* **Specific**: Clearly define your focus and what you’re going to do.
* **Measurable**: Quantify an indicator of progress, such as percentages, numbers, targets; or define it such that it is easy to identify if the project is complete.
* **Achievable**: Set the bar high, yet make it achievable.
* **Relevant**: Ensure that the Measure supports the company’s and your manager’s V2MOM Measures.
* **Timely**: Set a specific and reasonable time frame for completion.
`
}