import { gql } from '@apollo/client';

const V2MOM_FRAGMENT = gql`
  fragment CoreV2momFields on V2mom {
    id
    name
    vision
    values
    current
    updatedAt
    owner {
      id
      name
    }
    collection {
      id
      name
    }
  }
`;

export default V2MOM_FRAGMENT;