import { useToast } from '@chakra-ui/react';

const useNotifier = () => {
  const toast = useToast();

  const defaultConfig = {
    title: 'Error',
    description: "You've encountered an error",
    status: 'warning',
    position: 'top',
    duration: 5000,
    isClosable: true,
  }

  const warning = (options) => {
    toast({
      ...defaultConfig,
      ...options,
    });
  }

  const formSubmissionError = () => {
    toast({
      ...defaultConfig,
      title: 'Submission Error',
      description: 'Check the form for errors and try again',
    });
  }

  const serverError = (error) => {
    toast({
      ...defaultConfig,
      title: 'Server Error',
      description: error,
    });
  }

  return { warning, formSubmissionError, serverError };
}

export { useNotifier };