import { Box, Flex, Text } from '@chakra-ui/react';
import Shell from '@components/common/Shell';
import { Button } from '@components/forms';
import LoginButton from '@components/common/LoginButton';

const Entry = () => {
  const DisplayText = ({children}) => {
    return (
      <Text fontSize={{ base: '7xl', lg: '8xl' }} fontWeight='thin' lineHeight='1.1'>{children}</Text>
    )
  }

  return (
    <Shell>
      <Flex direction={{ base: 'column', lg: 'row' }} justifyContent='center'>
        <Box mx={{ lg: 8 }} pb={8}>
          <Text mb={8}><Text as='span' fontWeight='bold'>FireHydrant V2MOM</Text> is for employees only. All information contained within is proprietary and confidential. You will need to login before you can access this site.</Text>
          <LoginButton As={Button} text='Login' />
        </Box>
        <Box
          px={{ base: 0, lg: 8 }}
          pt={{ base: 8, lg: 0 }}
          borderLeft={{ base: '0', lg: '1px solid rgba(0, 0, 0, 0.24)' }}
          borderTop={{ base: '1px solid rgba(0, 0, 0, 0.24)', lg: '0' }}
        >
          <DisplayText>Vision</DisplayText>
          <DisplayText>Values</DisplayText>
          <DisplayText>Methods</DisplayText>
          <DisplayText>Obstacles</DisplayText>
          <DisplayText>Measures</DisplayText>
        </Box>
      </Flex>

    </Shell>
  );
}

export default Entry;