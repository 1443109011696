import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { CURRENT_USER } from '@queries/Users';
import { useAuthContext } from '@contexts/AuthProvider';
import { useUserContext } from '@contexts/UserProvider';
import { useSession } from '@utils/Session';
import { useNotifier } from '@utils/Notify';
import { useTimeout } from '@utils/SessionTimeout';
import Loader from '@components/common/Loader';

const Protected = ({Page}) => {
  const [ verifying, setVerifying ] = useState(true);
  const [ delayQuery, setDelayQuery ] = useState(true);
  const { state: { isAuthenticated }, dispatch: authDispatch } = useAuthContext();
  const { dispatch: userDispatch } = useUserContext();
  const navigate = useNavigate();
  const { warning } = useNotifier();
  const { getSession, sessionExists } = useSession();
  const { TimeoutModal } = useTimeout();

  const { loading } = useQuery(CURRENT_USER, {
    skip: delayQuery,
    onCompleted: (data) => {
      const [ token, expires ] = getSession();

      userDispatch({ type: 'SET_USER', payload: { user: data.currentUser } });
      authDispatch({ type: 'LOGIN', payload: {
        login: {
          token: token,
          expires: expires
      }}});
      setVerifying(false);
    },
    onError: () => {
      warning({
        title: 'Access Error',
        description: 'You must be logged in to view that page',
      });
      authDispatch({ type: 'LOGOUT', payload: {} });
      navigate('/login');
    }
  });

  useEffect(() => {
    if (!isAuthenticated) {
      if(sessionExists) {
        setDelayQuery(false);
      } else {
        warning({
          title: 'Access Error',
          description: 'You must be logged in to view that page',
        });
        authDispatch({ type: 'LOGOUT', payload: {} });
        navigate('/login');
      }
    } else {
      setVerifying(false);
    }
  }, [authDispatch, isAuthenticated, sessionExists, warning, navigate]);

  if (verifying) return <Loader />;
  if (loading) return <Loader />;

  return (
    <>
      <TimeoutModal />
      <Page />
    </>
  );
}

export default Protected;