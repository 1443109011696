import { useCallback, useRef } from 'react';
import {
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { LoginCallback, Security } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import oktaAuth from '@adapters/okta';

import Protected from '@utils/Protected';
import Error404 from '@pages/404';
import Login from '@pages/Login';
import Authenticate from '@pages/Authenticate';
import Dashboard from '@pages/Dashboard';
import Entry from '@pages/Entry';
import Help from '@pages/Help';
import { ListV2mom, EditV2mom, NewV2mom, EditDetailsV2mom, SearchV2mom, ViewV2mom } from '@pages/V2moms';


function App() {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  const restoreOriginalUri = useCallback((_oktaAuth,  originalUri) => {
    navigateRef.current(toRelativeUrl(originalUri || '/', window.location.origin));
  }, [navigateRef]);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path="/" element={<Entry />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/callback" element={<LoginCallback />} />
        <Route path="/authenticate" element={<Authenticate />} />
        <Route path="/dashboard" element={<Protected Page={Dashboard} />} />
        <Route path="/v2moms" element={<Protected Page={ListV2mom} />} />
        <Route path="/v2moms/search" element={<Protected Page={SearchV2mom} />} />
        <Route path="/v2moms/new" element={<Protected Page={NewV2mom} />} />
        <Route path="/v2moms/:id" element={<Protected Page={ViewV2mom} />} />
        <Route path="/v2moms/:id/update" element={<Protected Page={EditV2mom} />} />
        <Route path="/v2moms/:id/edit/details" element={<Protected Page={EditDetailsV2mom} />} />
        <Route path="/help" element={<Protected Page={Help} />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Security>
  );
}

export default App;