import { gql } from '@apollo/client';
import METHOD_FRAGMENT from '../V2moms/FragmentMethod';
import MEASURE_FRAGMENT from '../V2moms/FragmentMeasure';

const DASHBOARD = gql`
${METHOD_FRAGMENT}
${MEASURE_FRAGMENT}

query Dashboard {
  currentUser {
    v2moms {
      id
      name
      current
      stats
      updatedAt
    }
    v2momMethods {
      ...CoreMethodFields
      stats
      v2mom {
        id
        name
        owner {
          name
        }
      }
    }
    measures {
      ...CoreMeasureFields
      v2momMethod {
        name
        priority
        v2mom {
          id
          name
        }
      }
    }
  }
}
`;

export default DASHBOARD;