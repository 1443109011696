import { useMutation } from '@apollo/client';
import { LOGIN } from '@queries/Users';
import { useUserContext } from '@contexts/UserProvider';
import { useAuthContext } from '@contexts/AuthProvider';
import { useNotifier } from '@utils/Notify';

const useLogin = () => {
  const { dispatch: userDispatch } = useUserContext();
  const { dispatch: authDispatch } = useAuthContext();
  const { serverError } = useNotifier();

  const result = useMutation(LOGIN,{
    onCompleted: (data) => {
      userDispatch({ type: 'SET_USER', payload: { user: data.login.user } });
      authDispatch({ type: 'LOGIN', payload: { login: data.login } });
    },
    onError: (error) => {
      serverError(error.message);
    },
  });

  return result;
}

export { useLogin };