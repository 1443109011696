import { gql } from '@apollo/client';
import UPDATE_FRAGMENT from './FragmentUpdate';


const ADD_UPDATE = gql`
${UPDATE_FRAGMENT}

mutation AddUpdate(
  $id:ID
  $status:String!
  $current:Float
  $comment:String
  $measureId:ID!
) {
  addUpdate(input:{
    params: {
      id:$id,
      measureId:$measureId,
      status:$status,
      current:$current,
      comment:$comment,
    }
  }) {
    update {
      ...CoreUpdateFields
    }
  }
}
`;

export default ADD_UPDATE;