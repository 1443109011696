import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Spacer,
  Text
} from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import 'github-markdown-css/github-markdown-light.css';

const Item = ({item}) => {
  return (
    <Accordion allowMultiple>
      <AccordionItem>
        <AccordionButton pl={1}>
          <Text fontWeight='semibold'>{item.question}</Text>
          <Spacer />
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pl={1}>
          <Box className="markdown-body">
            <ReactMarkdown children={item.answer} remarkPlugins={[remarkGfm]} />
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default Item;