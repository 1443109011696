import { useDisclosure } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { Button } from '@components/forms';
import { PlusIcon } from '@components/icons';
import NewMeasuerModal from './NewMeasureModal';


const AddMeasureButton = ({methodId}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box pt={1}>
      <Button leftIcon={<PlusIcon />} size='sm' width='100%' onClick={onOpen} borderRadius='sm' >
        Add measure
      </Button>
      <NewMeasuerModal methodId={methodId} isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

export default AddMeasureButton;