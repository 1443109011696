import { Box, Textarea as ChakraTextarea } from '@chakra-ui/react';
import Label from './Label';
import { inputStyle } from './Styles';
import MarkdownTip from './MarkdownTip';

const Textarea = ({label, error, required, markdown, ...props}) => {
  return (
    <Box py={6}>
      <Label error={error} required={required}>{label}</Label>
      <ChakraTextarea {...inputStyle} {...props} />
      {markdown && <MarkdownTip />}
    </Box>
  );
}

export default Textarea;