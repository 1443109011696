import { gql } from '@apollo/client';

const USER_FRAGMENT = gql`
  fragment CoreUserFields on User {
    id
    name
    email
  }
`;

export default USER_FRAGMENT;