import React from 'react';
import { useSession } from '@utils/Session';

const UserContext = React.createContext();
const initialState = {
  user: null
};

const UserReducer = (state, { type, payload }) => {
  const { removeSession } = useSession();

  switch (type) {
    case "SET_USER":
      return {
        ...state,
        user: payload.user
      };
    case "CLEAR_USER":
      removeSession();
      return {
        ...state,
        user: null
      };
      default:
        return state;
  }
}

const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
}

const useUserState = () => {
  const { state } = useUserContext();
  if (state === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return state;
}

const useUserDispatch = () => {
  const { dispatch } = useUserContext();
  if (dispatch === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return dispatch;
}

const UserProvider = ({children}) => {
  const [state, dispatch] = React.useReducer(UserReducer, initialState);
  return (
    <UserContext.Provider value={{state, dispatch}}>
      {children}
    </UserContext.Provider>
  );
}

export { UserProvider, useUserContext, useUserDispatch, useUserState };