export const WhatAreStatuses = {
  question: "What do each of the statuses mean?",
  answer: `
Progress isn't always linear. When you're updating your V2MOM you'll need to make some judgment calls. For completion-based (“Do a thing”), you'll need to decide the current status of that project. For progress-based (“Create 100 widgets”), you’ll also have to use your judgment since progress isn’t always linear, but a safe estimate is that you should be about 25% of the way through your progress after one quarter.
* **On track**: 90-100% of expected progress
* **At risk**: 70-90% of expected progress
* **Off track**: <70% of expected progress
* **Not started**: Planned for a future quarter
* **Canceled**: We’ve intentionally changed tactics and won’t do this measure
* **Complete**: Great job!

In addition to the status for a measure, you should also leave short comments about the progress. This typically includes a list of achievements that went towards the progress (e.g., what deals were closed, which features were shipped, etc). For completion-based measures, the comments are a great way to share where you are in the project. 

For any measures that are At risk or Off track, you should communicate with your manager on your plan to get those projects back on track this quarter and include that plan in your update comments.
`
}