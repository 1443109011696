import {
  ButtonGroup,
  Heading,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { PRIORITIZE_METHOD } from '@queries/V2moms';
import { Button } from '@components/forms';
import EditMethodModal from './EditMethodModal';
import { useV2momContext } from '@contexts/V2momProvider';
import { ArrowDownIcon, ArrowUpIcon } from '@components/icons';
import { useNotifier } from '@utils/Notify';

const MethodHeader = ({method}) => {
  const { state, dispatch } = useV2momContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { serverError } = useNotifier();

  const { id, name, priority, owner } = method;
  const displayName = owner ? ` - ${owner.name}` : '';

  const [changePriority] = useMutation(PRIORITIZE_METHOD,{
    onCompleted: (data) => {
      dispatch({ type: 'UPDATE_METHOD', payload: { method: data.prioritizeV2momMethod.v2momMethod } })
    },
    onError: (error) => {
      serverError(error.message)
    },
  });

  const increasePriority = () => {
    changePriority({ variables: {
      id: id,
      priority: priority-1,
    }});
  }

  const decreasePriority = () => {
    changePriority({ variables: {
      id: id,
      priority: priority+1,
    }});
  }

  const menuPlacement = useBreakpointValue({ base: 'top-start', lg: 'left-start'})

  return (
    <>
      {state.edit ?
        <>
          <Popover trigger='hover' placement={menuPlacement} openDelay='0' closeDelay='300'>
            <PopoverTrigger>
              <Heading size='md' mb={3} mr={2} display='inline-block'>M{priority}: {name}{displayName}</Heading>
            </PopoverTrigger>
            <PopoverContent borderRadius='sm' w='min-content' border='none' boxShadow='none'>
              <HStack>
                <Button onClick={onOpen} fontSize='xs' borderRadius='full' px={3} py={1} h='auto'>Edit</Button>
                <ButtonGroup isAttached>
                  { (priority !== 1) ? <Button onClick={increasePriority} fontSize='xs' borderRadius='full' p={0} py={1} h='auto'><ArrowUpIcon /></Button> : null }
                  { (priority !== state.v2momMethods.length) ? <Button onClick={decreasePriority} fontSize='xs' borderRadius='full' p={0} py={1} h='auto'><ArrowDownIcon /></Button> : null }
                </ButtonGroup>
              </HStack>
            </PopoverContent>
          </Popover>
          <EditMethodModal methodId={id} isOpen={isOpen} onClose={onClose} />
        </>
      : <Heading size="md" mb={3} mr={2} display='inline-block'>M{priority}: {name}{displayName}</Heading> }
    </>
  );
}

export default MethodHeader;