import { gql } from '@apollo/client';


const GET_ALL_USERS = gql`
query GetUsers {
  users {
    id
    name
  }
}
`;

export default GET_ALL_USERS;