import { Box, HStack } from '@chakra-ui/react';


const MeasureTableHeader = () => {
  return (
    <Box mb={1} display={{ base: 'none', lg: 'block' }}>
      <HStack>
        <Box w="40%" fontSize='xs' color='blackAlpha.600' textTransform='uppercase'>Description</Box>
        <Box w="20%" fontSize='xs' color='blackAlpha.600' textTransform='uppercase'>Progress</Box>
        <Box w="20%" fontSize='xs' color='blackAlpha.600' textTransform='uppercase'>Owner</Box>
        <Box w="15%" fontSize='xs' color='blackAlpha.600' textTransform='uppercase'>Updated</Box>
        <Box w="5%" fontSize='xs' color='blackAlpha.600' textTransform='uppercase' />
      </HStack>
    </Box>
  );
}

export default MeasureTableHeader;