import { Box, Text } from '@chakra-ui/react';
import { Datetime } from '@components/common/Datetime';

const DueDates = () => {
  const deadlines = [
    { date: "2022-08-01T23:59:59Z", message: "Beginning of Q3" },
    { date: "2022-09-23T23:59:59Z", message: "Review your V2MOM revisions with your manager" },
    { date: "2022-09-30T23:59:59Z", message: "V2MOM refresh complete, let's go!" },
  ]

  const upcomingDates = deadlines.map((deadline, i) => (
    <Box key={i} mb={4}>
      <Text fontSize='xs' color='blackAlpha.600'><Datetime timestamp={deadline.date} format='dateFull' /></Text>
      <Text fontSize='sm'>{deadline.message}</Text>
    </Box>
  ));

  return (
    <Box>
      <Text lineHeight='shorter' fontSize='3xl' fontWeight='light' mb={4}>Important dates</Text>
      {upcomingDates}
    </Box>
  );
}

export default DueDates;