import {
  Box,
  Heading,
} from '@chakra-ui/react';
import Shell from '@components/common/Shell';
import V2momForm from './V2momForm';


const NewV2mom = () => {
  const initialValues = {
    id: null,
    collection: '',
    name: '',
    vision: '',
    values: '',
  }

  return (
    <Shell>
      <Box>
        <Heading mb={8}>Create a new V2MOM</Heading>
        <V2momForm initialValues={initialValues} submitLabel='Create' />
      </Box>
    </Shell>
  );
}

export default NewV2mom;

