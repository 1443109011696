import { gql } from '@apollo/client';

const LIST_V2MOM = gql`
query ListV2mom {
  v2moms {
    id
    name
    updatedAt
    stats
    current
    collection {
      name
    }
    owner {
      name
    }
  }
}
`;

export default LIST_V2MOM;