import { createContext, useContext, useReducer } from 'react';

const V2momContext = createContext();

const V2momReducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_V2MOM":
      return {
        ...state,
        v2mom: payload.v2mom
      };
    case "ADD_METHOD":
      return {
        ...state,
        v2momMethods: [...state.v2momMethods, payload.method]
      };
    case "UPDATE_METHOD":
      let updatedMethods = state.v2momMethods.map((method) => {
        if (method.id === payload.method.id) {
          return {...method, ...payload.method};
        }
        return method;
      });
      updatedMethods.sort((a,b) => (a.priority > b.priority) ? 1 : -1)
      updatedMethods.map((method,index) => {
        return method.priority = index + 1;
      });
      return {
        ...state,
        v2momMethods: updatedMethods
      };
    case "DELETE_METHOD":
      let methods = state.v2momMethods.filter((method) => {
        return method.id !== payload.method.id;
      });
      methods.map((method,index) => {
        return method.priority = index + 1;
      });
      return {
        ...state,
        v2momMethods: methods
      };
    case "ADD_MEASURE":
      const methodsWithNewMeasure = state.v2momMethods.map((method) => {
        if (method.id === payload.measure.v2momMethodId) {
          return {
            ...method,
            measures: [...method.measures, payload.measure]
          }
        }
        return method;
      });
      return {
        ...state,
        v2momMethods: methodsWithNewMeasure
      };
    case "UPDATE_MEASURE":
      const withUpdatedMeasure = state.v2momMethods.map((method) => {
        if (method.id === payload.measure.v2momMethodId) {
          const updatedMeasures = method.measures.map((measure) => {
            if (measure.id === payload.measure.id) {
              return {...measure, ...payload.measure};
            }
            return measure;
          });
          updatedMeasures.sort((a,b) => (a.priority > b.priority) ? 1 : -1)
          updatedMeasures.map((measure,index) => {
            return measure.priority = index + 1;
          });
          return {
            ...method,
            measures: updatedMeasures
          };
        }
        return method;
      });
      return {
        ...state,
        v2momMethods: withUpdatedMeasure
      }
    case "DELETE_MEASURE":
      const withDeletedMeasure = state.v2momMethods.map((method) => {
        if (method.id === payload.measure.v2momMethodId) {
          let updatedMeasures = method.measures.filter((measure) => {
            return measure.id !== payload.measure.id
          });
          updatedMeasures.map((measure,index) => {
            return measure.priority = index + 1;
          });
          return {
            ...method,
            measures: updatedMeasures
          };
        }
        return method;
      });
      return {
        ...state,
        v2momMethods: withDeletedMeasure
      }
    case "ADD_UPDATE":
      const methodsWithNewUpdate = state.v2momMethods.map((method) => {
        if (method.id === payload.update.methodId) {
          const updatedMeasures = method.measures.map((measure) => {
            if (measure.id === payload.update.measureId) {
              
              measure.status = payload.update.status
              measure.current = payload.update.current

              return {
                ...measure,
                updates: [payload.update, ...measure.updates]
              }
            }
            return measure;
          });
          return {
            ...method,
            measures: updatedMeasures
          };
        }
        return method;
      });
      return {
        ...state,
        v2momMethods: methodsWithNewUpdate
      };
    case "UPDATE_UPDATE":
      const methodsWithUpdatedUpdate = state.v2momMethods.map((method) => {
        if (method.id === payload.update.methodId) {
          const updatedMeasures = method.measures.map((measure) => {
            if (measure.id === payload.update.measureId) {
              
              measure.status = payload.update.status
              measure.current = payload.update.current

              const updatedUpdates = measure.updates.map((update) => {
                if (update.id === payload.update.id) {
                  return payload.update;
                }
                return update;
              });

              return {
                ...measure,
                updates: updatedUpdates
              }
            }
            return measure;
          });
          return {
            ...method,
            measures: updatedMeasures
          };
        }
        return method;
      });
      return {
        ...state,
        v2momMethods: methodsWithUpdatedUpdate
      };
    case "DELETE_UPDATE":
      const withDeletedUpdate = state.v2momMethods.map((method) => {
        if (method.id === payload.update.methodId) {
          const updatedMeasures = method.measures.map((measure) => {
            if (measure.id === payload.update.measureId) {
              const updatedUpdates = measure.updates.filter((update) => {
                return update.id !== payload.update.id
              });

              measure.status = 'notStarted'
              measure.current = 0

              if (updatedUpdates[0]) {
                measure.status = updatedUpdates[0].status
                measure.current = updatedUpdates[0].current
              }

              return {
                ...measure,
                updates: updatedUpdates
              }
            }
            return measure;
          });
          return {
            ...method,
            measures: updatedMeasures
          };
        }
        return method;
      });
      return {
        ...state,
        v2momMethods: withDeletedUpdate
      };
    default:
      return state;
  }
}

const useV2momContext = () => {
  const context = useContext(V2momContext);
  if (context === undefined) {
    throw new Error('useV2momContext must be used within a V2momProvider');
  }
  return context;
}

const V2momProvider = ({children, initialState}) => {
  const [state, dispatch] = useReducer(V2momReducer, initialState);
  return (
    <V2momContext.Provider value={{state, dispatch}}>
      {children}
    </V2momContext.Provider>
  );
}

export { V2momProvider, useV2momContext, V2momReducer, V2momContext };