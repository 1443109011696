import { Badge } from '@chakra-ui/react';


const YearBadge = ({name, current}) => {
  const defaultStyles = {
    color: 'gray.600',
    bgColor: 'blackAlpha.200'
  }

  const currentStyles = {
    color: 'white',
    bgColor: 'green.600'
  }

  return(
    <Badge { ...(current ? currentStyles : defaultStyles) } mr='1' px='2' borderRadius='full'>{name}</Badge>
  );
}

export default YearBadge;