import {
  Flex,
  Heading,
  Spacer,
} from '@chakra-ui/react';
import NewMethodButton from './NewMethodButton';
import { useV2momContext } from '@contexts/V2momProvider';


const MethodsHeader = ({methodCount, ...props}) => {
  const { state: v2mom } = useV2momContext();

  return (
    <Flex {...props} align='center'>
      <Heading size='xl' mb={4}>Methods</Heading>
      {methodCount > 0 &&
        <>
          <Spacer />
          {v2mom.edit && <NewMethodButton />}
        </>
      }
    </Flex>
  );
}

export default MethodsHeader;