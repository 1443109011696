import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { Button } from '@components/forms';
import { useQuery } from '@apollo/client';
import MeasureForm from './MeasureForm';
import { GET_MEASURE } from '@queries/V2moms';
import DeleteMeasureModal from './DeleteMeasureModal';

const EditMeasuerModal = ({measureId, isOpen, onClose}) => {
  const dispatchType = 'UPDATE_MEASURE';
  const deleteModal = useDisclosure();

  const { loading, error, data } = useQuery(GET_MEASURE, {
    variables: {
      id: measureId,
    },
  });

  if (loading) return <p>Loading ...</p>;
  if (error) return `${error}`;

  const initialValues = {
    id: data.measure.id,
    methodId: data.measure.v2momMethodId,
    priority: data.measure.priority,
    name: data.measure.name,
    ownerId: data.measure.owner?.id,
    measureType: data.measure.measureType,
    target: data.measure.target,
  }

  const confirmDelete = () => {
    deleteModal.onOpen();
  };

  return (
    <>
      <Modal autoFocus={false} isOpen={isOpen} onClose={onClose} size='2xl' motionPreset='slideInBottom' isCentered>
        <ModalOverlay />
        <ModalContent borderRadius='sm'>
          <ModalHeader as={Flex}>
            Edit measure
            <Spacer />
            <Button variant='dangerGhost' size='sm' onClick={confirmDelete}>Delete</Button>
          </ModalHeader>
          <ModalBody>
            <MeasureForm initialValues={initialValues} dispatchType={dispatchType} closeModal={onClose} submitLabel='Update' />
          </ModalBody>
        </ModalContent>
      </Modal>
      <DeleteMeasureModal measure={initialValues} modalDisclosure={deleteModal} />
    </>
  );
}

export default EditMeasuerModal;