import {
  Button as ChakraButton,
  useBreakpointValue
} from '@chakra-ui/react';

const baseStyles = {
  borderRadius: 'full',
  bg: 'blackAlpha.200',
  _hover: {
    bg: 'green.600',
    color: 'white'
  }
}

const primaryStyles = {
  bg: 'green.600',
  color: 'white',
  _hover: {
    bg: 'green.800',
  },
  _active: {
    bg: 'green.500'
  },
  _focus: {
    boxShadow: '0 0 0 3px #68D391',
  }
}

const secondaryStyles = {
  bg: 'blackAlpha.200',
  color: 'blackAlpha.900',
  _hover: {
    bg: 'blackAlpha.300'
  },
  _active: {
    bg: 'blackAlpha.500'
  },
  _focus: {
    boxShadow: '0 0 0 3px #68D391',
  }
}

const outlineStyles = {
  bg: 'none',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'blackAlpha.500',
  color: 'blackAlpha.900',
  _hover: {
    bg: 'blackAlpha.100'
  },
  _active: {
    bg: 'blackAlpha.300'
  },
  _focus: {
    boxShadow: '0 0 0 3px #68D391',
  }
}

const ghostStyles = {
  bg: 'none',
  color: 'blackAlpha.900',
  _hover: {
    bg: 'blackAlpha.100'
  },
  _active: {
    bg: 'blackAlpha.300'
  },
  _focus: {
    boxShadow: '0 0 0 3px rgba(0, 0, 0, 0.48)',
  }
}

const dangerPrimary = {
  bg: 'red.600',
  color: 'white',
  _hover: {
    bg: 'red.800',
  },
  _active: {
    bg: 'red.500'
  },
  _focus: {
    boxShadow: '0 0 0 3px #9B2C2C',
  }
}

const dangerSecondary = {
  bg: 'blackAlpha.200',
  color: 'red.600',
  _hover: {
    bg: 'red.600',
    color: 'white'
  },
  _active: {
    bg: 'red.400'
  },
  _focus: {
    boxShadow: '0 0 0 3px #9B2C2C',
  }
}

const dangerOutline = {
  bg: 'none',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'red.600',
  color: 'red.600',
  _hover: {
    bg: 'red.600',
    color: 'white'
  },
  _active: {
    bg: 'red.400'
  },
  _focus: {
    boxShadow: '0 0 0 3px #9B2C2C',
  }
}

const dangerGhost = {
  bg: 'none',
  color: 'red.700',
  _hover: {
    bg: 'red.50'
  },
  _active: {
    bg: 'red.200'
  },
  _focus: {
    boxShadow: '0 0 0 3px #9B2C2C',
  }
}


const Button = ({ children, variant, responsive, ...props}) => {
  const buttonSize = useBreakpointValue({ base: 'xs', lg: 'md'})

  const styleMap = {
    'primary': primaryStyles,
    'secondary': secondaryStyles,
    'outline': outlineStyles,
    'ghost': ghostStyles,
    'dangerPrimary': dangerPrimary,
    'dangerSecondary': dangerSecondary,
    'dangerOutline': dangerOutline,
    'dangerGhost': dangerGhost,
  }
  
  const style = styleMap[variant];

  return (
    <ChakraButton
      size={responsive ? buttonSize : null}
      {...baseStyles}
      {...style}
      {...props}
    >
      {children}
    </ChakraButton>
  );
}

export default Button;