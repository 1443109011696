import { useState } from 'react';
import { Box, Button, Flex, HStack } from '@chakra-ui/react';
import { ArrowDownIcon, ArrowUpIcon } from '@components/icons';
import V2momTableRow from './V2momTableRow';

const V2momTable = ({v2moms}) => {
  const [ sortBy, setSortBy ] = useState(['name','ascending']);

  const SortHeader = ({children, sortKey, ...props}) => (
    <Button
      variant='unstyled'
      height='auto'
      fontSize='xs'
      color='blackAlpha.600'
      textTransform='uppercase'
      {...props}
    >
      <Flex align="center">
        {children}
        <SortIndicator sortKey={sortKey} />
      </Flex>
    </Button>
  );

  const SortIndicator = ({sortKey}) => {
    const [ column, direction ] = sortBy;

    if (sortKey === column) {
      return direction === 'ascending' ? <ArrowDownIcon w={4} h={4} /> : <ArrowUpIcon w={4} h={4} />;
    }
    return <></>;
  }

  const handleSort = (column) => {
    const [ currentColumn, currentDirection ] = sortBy;

    if (currentColumn === column) {
      const direction = (currentDirection === 'ascending' ? 'descending' : 'ascending')
      setSortBy([column, direction]);
    } else {
      setSortBy([column, 'ascending']);
    }
  }

  const sortableData = v2moms.map((v2mom) => (
    {
      id: v2mom.id,
      name: v2mom.name,
      year: v2mom.collection.name,
      current: v2mom.current,
      owner: v2mom.owner.name,
      complete: ((v2mom.stats.complete/v2mom.stats.total*100) || 0).toFixed(0),
      updatedAt: v2mom.updatedAt,
    }
  ));

  if (sortBy !== null) {
    const [field, direction] = sortBy;

    sortableData.sort((a,b) => {
      if (a[field] < b[field]) {
        return direction === 'descending' ? 1 : -1;
      }
      if (a[field] > b[field]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }

  const table = sortableData.map((v2mom) => <V2momTableRow key={v2mom.id} v2mom={v2mom} />)

  return (
    <Box ml={-2}>
      <Box mb={1}>
        <HStack>
          <Box w="40%" pl={2}>
            <SortHeader sortKey='name' onClick={() => handleSort('name')}>Name</SortHeader>
          </Box>
          <Box w="10%">
            <SortHeader sortKey='owner' onClick={() => handleSort('year')}>Year</SortHeader>
          </Box>
          <Box w="20%">
            <SortHeader sortKey='owner' onClick={() => handleSort('owner')}>Owner</SortHeader>
          </Box>
          <Box w="15%">
            <SortHeader sortKey='complete' onClick={() => handleSort('complete')}>Complete</SortHeader>
          </Box>
          <Box w="15%" align='right' pr={2}>
            <SortHeader sortKey='updatedAt' onClick={() => handleSort('updatedAt')}>Updated</SortHeader>
          </Box>
        </HStack>
      </Box>
      {table}
    </Box>
  );
}

export default V2momTable