import { Box, HStack, Text } from '@chakra-ui/react';
import MeasureRow from './MeasureRow';

const MeasureList = ({measures}) => {
  if (measures.length === 0) return <Text fontStyle='italic'>You are not the owner of any measures.</Text>;

  const measureList = measures.map((measure) => (
    <MeasureRow key={measure.id} measure={measure} />
  ));

  return (
    <>
      <HStack
        pb={2}
        borderBottomWidth='1px'
        borderBottomStyle='solid'
        borderBottomColor='blackAlpha.200'
        display={{ base: 'none', lg: 'flex' }}
      >
        <Box w="60%" fontSize='xs' color='blackAlpha.600' textTransform='uppercase'>Measure</Box>
        <Box w="20%" fontSize='xs' color='blackAlpha.600' textTransform='uppercase'>Progress</Box>
        <Box w="10%" fontSize='xs' color='blackAlpha.600' textTransform='uppercase'>Updated</Box>
        <Box w="10%" fontSize='xs' color='blackAlpha.600' textTransform='uppercase' />
      </HStack>
      {measureList}
    </>
  );
}

export default MeasureList;