import { gql } from '@apollo/client';


const DELETE_MEASURE = gql`
mutation DeleteMeasure($id: ID!) {
  deleteMeasure(input: {id: $id}) {
    measure {
      id
      v2momMethodId
    }
  }
}
`;

export default DELETE_MEASURE;