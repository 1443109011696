import {
  Stack
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Button } from '@components/forms';
import { useV2momContext } from '@contexts/V2momProvider';

const Editing = ({id}) => {
  return (
    <Stack direction='row' spacing={1} my={4} justifyContent='right'>
      <Link to={`/v2moms/${id}/edit/details`}>
        <Button mr={2} variant='ghost'>
          Edit details
        </Button>
      </Link>
      <Link to={`/v2moms/${id}`}>
        <Button variant='primary'>
          Done
        </Button>
      </Link>
    </Stack>
  );
}

const Edit = ({id}) => {
  return (
    <Stack direction='row' spacing={1} my={4} justifyContent='right'>
      <Link to={`/v2moms/${id}/update`}>
        <Button variant='primary'>
          Update
        </Button>
      </Link>
    </Stack>
  );
}

const ActionButtons = () => {
  const { state: v2mom } = useV2momContext();

  if (v2mom.edit) return <Editing id={v2mom.id} />;
  return <Edit id={v2mom.id} />;
}

export default ActionButtons;