import { gql } from '@apollo/client';

const METHOD_FRAGMENT = gql`
  fragment CoreMethodFields on V2momMethod {
    id
    name
    priority
    obstacles
    owner {
      id
      name
    }
  }
`;

export default METHOD_FRAGMENT;