import { 
  Avatar,
  Box,
  Flex,
  Spacer,
  Stack,
  Tag,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Button } from '@components/forms';
import statusMap from '@components/common/StatusMap';
import { Datetime } from '@components/common/Datetime';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import EditUpdateModal from '../Updates/EditUpdateModal';
import { useUserState } from '@contexts/UserProvider';
import { useV2momContext } from '@contexts/V2momProvider';

const StatusMessage = ({update, measure}) => {
  const displayCurrent = update.current ? update.current : 0;

  if (measure.measureType === 'progress') {
    return <Text fontSize="sm" fontWeight="bold" mr={2}>Updated to {displayCurrent} of {measure.target}</Text>;
  }
  return <></>;
}

const MeasureUpdate = ({update, measure}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user: currentUser } = useUserState();
  const { state: v2mom } = useV2momContext();

  const ShowEdit = () => {
    if (currentUser.id === update.author.id && v2mom.edit) {
      return (
        <Box>
          <Stack direction='row' spacing={2} justifyContent='right'>
            <Button variant='ghost' size='sm' onClick={onOpen}>Edit</Button>
          </Stack>
          <EditUpdateModal update={update} measure={measure} isOpen={isOpen} onClose={onClose} />
        </Box>
      );
    }
    return <></>;
  }

  const MobileUpdate = () => {
    return (
      <Box p={4} bg='blackAlpha.100' borderRadius='md'>
        <Box mb={4}>
          <Text as='span' fontSize='sm' fontWeight='bold'>{update.author.name}</Text>
          <Text as='span' fontSize='sm' color='blackAlpha.600'> on <Datetime timestamp={update.updatedAt} format='datetime' /></Text>
        </Box>
        <Flex align='center'>
          <Tag borderRadius='full' mr={1} bg={statusMap[update.status].background} color={statusMap[update.status].text}>{statusMap[update.status].title}</Tag>
          <StatusMessage update={update} measure={measure} />
        </Flex>
        <Box className='markdown-body' bg='none' py={4} pr={4}>
          <ReactMarkdown children={update.comment} remarkPlugins={[remarkGfm]} />
        </Box>
        <ShowEdit author={update.author} />
      </Box>
    );
  }

  const DesktopUpdate = () => {
    return (
      <Box p={4} bg='blackAlpha.100' borderRadius='md'>
        <Flex>
          <Box>
            <Avatar mr={2} name={update.author.name} src={update.author.avatar} />
          </Box>
          <Box flex='1'>
            <Box>
              <Flex mb={2} align='center'>
                <Box>
                  <Text fontSize='sm' fontWeight='bold'>{update.author.name}</Text>
                  <Text fontSize='sm' color='blackAlpha.600'><Datetime timestamp={update.updatedAt} format='datetime' /></Text>
                </Box>
                <Spacer />
                <StatusMessage update={update} measure={measure} />
                <Tag borderRadius='full' bg={statusMap[update.status].background} color={statusMap[update.status].text}>{statusMap[update.status].title}</Tag>
              </Flex>
            </Box>
            <Box className='markdown-body' bg='none' py={4} pr={4}>
              <ReactMarkdown children={update.comment} remarkPlugins={[remarkGfm]} />
            </Box>
          </Box>
        </Flex>
        <ShowEdit author={update.author} />
      </Box>
    );
  }
  const responsive = useBreakpointValue({ base: <MobileUpdate />, lg: <DesktopUpdate /> })

  return (
    <>
      {responsive}
    </>
  );
}

export default MeasureUpdate;