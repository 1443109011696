import {
  Center,
  Text,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@components/icons';
import { Button } from '@components/forms';


const DetailPlaceholder = ({ message }) => {
  return (
    <Center h={48} mt={2} flexDirection='column' bg='blackAlpha.50' borderBottomWidth='1px' borderBottomStyle='sollid' borderBottomColor='green.600'>
      <Text mb={4} fontWeight='bold' color='blackAlpha.900'>{message}</Text>
      <Link to="details">
        <Button variant='primary' borderRadius='full' w='16' h='16' boxShadow='md'>
          <PlusIcon w='8' h='8' color='white' />
        </Button>
      </Link>
    </Center>
  );
}

export default DetailPlaceholder;