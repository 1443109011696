import { Button } from '@components/forms';
import { useAuthState } from '@contexts/AuthProvider';
import CurrentUserNavigation from './CurrentUserNavigation';
import LoginButton from './LoginButton';

const Navigation = () => {
  const { isAuthenticated } = useAuthState();
  if (isAuthenticated) return <CurrentUserNavigation />;

  return (
    <LoginButton As={Button} text='Login' />
  );
}

export default Navigation;