import { gql } from '@apollo/client';
import METHOD_FRAGMENT from './FragmentMethod';

const GET_METHOD = gql`
${METHOD_FRAGMENT}

query GetMethod($id: ID!) {
  v2momMethod (id: $id) {
    ...CoreMethodFields
  }
}
`;

export default GET_METHOD;