import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Button } from '@components/forms';
import { useMutation } from '@apollo/client';
import { DELETE_METHOD } from '@queries/V2moms';
import { useV2momContext } from '@contexts/V2momProvider';
import { useNotifier } from '@utils/Notify';


const DeleteMethodModal = ({modalDisclosure, method}) => {
  const { serverError } = useNotifier();
  const { dispatch } = useV2momContext();

  const [deleteMethod, { loading }] = useMutation(DELETE_METHOD,{
    onCompleted: (data) => {
      dispatch({ type: 'DELETE_METHOD', payload: { method: data.deleteV2momMethod.v2momMethod } })
      modalDisclosure.onClose();
    },
    onError: (error) => {
      serverError(error.message)
    },
  });
  
  const handleDelete = () => {
    deleteMethod({ variables: {
      id: method.id,
    }});
  }

  if (loading) return <p>Loading ...</p>;

  return (
    <Modal isOpen={modalDisclosure.isOpen} onClose={modalDisclosure.onClose} size='lg' motionPreset='slideInBottom' isCentered>
      <ModalOverlay />
      <ModalContent borderRadius='sm'>
        <ModalHeader>
          Delete method
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete <Text as='span' fontWeight='bold' color='red.600'>{method.name}</Text>? Deleting this method will also delete all measures and updates associated with it.
          <Text pt={4} fontWeight='bold'>This action cannot be undone.</Text>
          <Stack direction='row' spacing={2} my={4} justifyContent='right'>
            <Button variant='ghost' onClick={modalDisclosure.onClose}>Close</Button>
            <Button variant='dangerOutline' onClick={handleDelete}>Delete</Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default DeleteMethodModal;