import { gql } from '@apollo/client';


const LOGOUT = gql`
mutation Logout {
  logout(input: {}) {
    user { id }
    token
  }
}
`;

export default LOGOUT;