import { Box, Input } from '@chakra-ui/react';
import Label from './Label';
import { inputStyle } from './Styles';


const TextInput = ({label, error, required, ...props}) => {
  return (
    <Box py={6}>
      <Label error={error} required={required}>{label}</Label>
      <Input {...inputStyle} {...props} />
    </Box>
  );
}

export default TextInput;