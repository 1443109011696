import { gql } from '@apollo/client';
import V2MOM_FRAGMENT from './FragmentV2mom';
import METHOD_FRAGMENT from './FragmentMethod';
import MEASURE_FRAGMENT from './FragmentMeasure';
import UPDATE_FRAGMENT from './FragmentUpdate';

const GET_V2MOM = gql`
${V2MOM_FRAGMENT}
${METHOD_FRAGMENT}
${MEASURE_FRAGMENT}
${UPDATE_FRAGMENT}

query GetV2mom($id: ID!) {
  v2mom (id: $id) {
    ...CoreV2momFields
    v2momMethods {
      ...CoreMethodFields
      measures {
        ...CoreMeasureFields
        updates {
          ...CoreUpdateFields
        }
      }
    }
  }
}
`;

export default GET_V2MOM;