import { Box } from '@chakra-ui/react';
import Shell from '@components/common/Shell';
import { useQuery } from '@apollo/client';
import { LIST_V2MOM } from '@queries/V2moms';
import Loader from '@components/common/Loader';
import PageHeader from '@components/common/PageHeader';
import V2momTable from '@components/V2mom/V2momTable';

const ListV2mom = () => {
  const { loading, error, data } = useQuery(LIST_V2MOM);

  if (loading) return <Loader />;
  if (error) return `${error}`;

  return (
    <Shell>
      <Box>
        <PageHeader divider>All V2MOMs</PageHeader>
        <V2momTable v2moms={data.v2moms} />
      </Box>
    </Shell>
  );
}

export default ListV2mom;