import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LOGOUT } from '@queries/Users';
import { useUserContext } from '@contexts/UserProvider';
import { useAuthContext } from '@contexts/AuthProvider';
import { useNotifier } from '@utils/Notify';

const LogoutButton = ({As, ...props}) => {
  const { dispatch: userDispatch } = useUserContext();
  const { dispatch: authDispatch } = useAuthContext();
  const { serverError } = useNotifier();
  const navigate = useNavigate();

  const [logout, { loading }] = useMutation(LOGOUT,{
    onCompleted: () => {
      navigate('/login');
      authDispatch({ type: 'LOGOUT', payload: {} });
      userDispatch({ type: 'CLEAR_USER', payload: {} });
    },
    onError: (error) => {
      serverError(error.message);
    },
  });

  if (loading) return <p>Loading ...</p>;

  return (
    <As onClick={logout} {...props}>Logout</As>
  );
}

export default LogoutButton;