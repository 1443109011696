import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
} from '@chakra-ui/react';
import UpdateForm from './UpdateForm';


const NewUpdateModal = ({measure, isOpen, onClose}) => {
  const dispatchType = 'ADD_UPDATE';

  const initialValues = {
    id: null,
    measureId: measure.id,
    status: '',
    current: (measure.current || ''),
    comment: '',
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl' motionPreset='slideInBottom' isCentered>
      <ModalOverlay />
      <ModalContent borderRadius='sm'>
        <ModalHeader pt='6'>
          <Text textTransform='uppercase' fontSize='sm' color='blackAlpha.600' lineHeight='short'>Add update</Text>
          <Text>{measure.name}</Text>
        </ModalHeader>
        <ModalBody>
          <UpdateForm type={measure.measureType} initialValues={initialValues} dispatchType={dispatchType} closeModal={onClose} submitLabel='Add' />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default NewUpdateModal;