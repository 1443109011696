import { Box, Text } from '@chakra-ui/react';
import Item from '@components/Help/Item';

const Section = ({section}) => {
  const items = section.entries.map((item,i) => <Item key={i} item={item} />);
  
  return (
    <Box mb={8}>
      <Text fontSize='xl' fontWeight='bold' pl={1}>{section.title}</Text>
      {items}
    </Box>
  );
}

export default Section;