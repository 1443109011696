import { gql } from '@apollo/client';
import UPDATE_FRAGMENT from './FragmentUpdate';

const GET_UPDATE = gql`
${UPDATE_FRAGMENT}

query GetUpdate($id:ID!) {
  update(id:$id){
    ...CoreUpdateFields
  }
}
`;

export default GET_UPDATE;