import { Box, HStack, Text } from '@chakra-ui/react';
import MethodRow from './MethodRow';

const MethodList = ({methods}) => {
  if (methods.length === 0) return <Text fontStyle='italic'>You are not the owner of any methods.</Text>;

  const methodList = methods.map((method) => (
    <MethodRow key={method.id} method={method} />
  ));

  return (
    <>
      <HStack
        pb={2}
        borderBottomWidth='1px'
        borderBottomStyle='solid'
        borderBottomColor='blackAlpha.200'
        display={{ base: 'none', lg: 'flex' }}
      >
        <Box w="40%" fontSize='xs' color='blackAlpha.600' textTransform='uppercase'>V2MOM (Owner)</Box>
        <Box w="30%" fontSize='xs' color='blackAlpha.600' textTransform='uppercase'>Method</Box>
        <Box w="25%" fontSize='xs' color='blackAlpha.600' textTransform='uppercase'>Complete</Box>
        <Box w="5%" fontSize='xs' color='blackAlpha.600' textTransform='uppercase' />
      </HStack>
      {methodList}
    </>
  );
}

export default MethodList;