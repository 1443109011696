import { gql } from '@apollo/client';
import USER_FRAGMENT from './FragmentUser';

const LOGIN = gql`
${USER_FRAGMENT}

mutation Login(
  $accessToken:String!
) {
  login(input:{
    params: {
      accessToken:$accessToken,
    }
  }) {
    user {
      ...CoreUserFields
      v2moms {
        id
        name
      }
    }
    token
    expires
  }
}
`;

export default LOGIN;