import {
  Circle,
  Flex,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Link } from "react-router-dom";
import { useAuthState } from '@contexts/AuthProvider';
import { SiteIcon } from '@components/icons';
import Navigation from './Navigation';

const Header = () => {
  const { isAuthenticated } = useAuthState();

  return (
    <Flex p={8} mb={8}>
      <Link to={ isAuthenticated ? "/v2moms/search" : "/login" }>
        <Stack direction='row' align='center' spacing={2}>
          <Circle bg='white' p={1} border='0' _hover={{ bg: 'green.600', color: 'white' }}>
            <SiteIcon />
          </Circle>
          <Text fontWeight="bold">
            FireHydrant V2MOM
          </Text>
        </Stack>
      </Link>
      <Spacer />
      <Navigation />
    </Flex>
  );
}

export default Header;