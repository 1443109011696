import { Box, Circle, Flex, Progress, Text, Tooltip } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import statusMap from '@components/common/StatusMap';

const Completion = ({status}) => {
  return (
    <Flex align='center'>
      <Circle size={4} bg={statusMap[status].background} mr={2} />
      <Text fontSize="sm">{statusMap[status].title}</Text>
    </Flex>
  );
}

const ProgressBar = ({status, target, current}) => {
  const percentage = (current/target*100);
  const currentDisplay = current ? current : 0;

  return (
    <Tooltip hasArrow borderRadius='full' label={`${currentDisplay} of ${target}`}>
      <Box justifyContent="center">
        <Progress w='80%' colorScheme={statusMap[status].colorScheme} size='sm' value={percentage}  borderRadius='full' bg='blackAlpha.300' mb={1} />
        <Text fontSize='sm'>{statusMap[status].title}</Text>
      </Box>
    </Tooltip>
  );
}

const Complete = () => {
  return (
    <Flex align='center'>
      <Circle size={4} bg='green.600' color='white' mr={2}>
        <CheckIcon w={2} h={2} />
      </Circle>
      <Text fontSize="sm">Complete</Text>
    </Flex>
  );
}

const StatusDisplay = ({type, status, complete, target, current}) => {
  if (complete) return <Complete />;
  if (type==='completion') return <Completion status={status} />;
  if (type==='progress') return <ProgressBar status={status} target={target} current={current} />;

  return (
    <Text>No Type</Text>
  );
}

export default StatusDisplay;