import React from 'react';
import { useSession } from '@utils/Session';

const AuthContext = React.createContext();
const initialState = {
  isAuthenticated: false,
  token: null,
  expires: null,
};

const AuthReducer = (state, { type, payload }) => {
  const { setSession, removeSession } = useSession();

  switch (type) {
    case "LOGIN":
      setSession({
        token: payload.login.token,
        expires: payload.login.expires,
      })
      return {
        ...state,
        isAuthenticated: true,
        expires: payload.login.expires,
        token: payload.login.token
      };
    case "LOGOUT":
      removeSession();
      return {
        ...state,
        isAuthenticated: false,
        expires: false,
        token: false
      };
      default:
        return state;
  }
}

const useAuthContext = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
}

const useAuthState = () => {
  const { state } = useAuthContext();
  if (state === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }
  return state;
}

const useAuthDispatch = () => {
  const { dispatch } = useAuthContext();
  if (dispatch === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return dispatch;
}

const AuthProvider = ({children}) => {
  const [state, dispatch] = React.useReducer(AuthReducer, initialState);
  return (
    <AuthContext.Provider value={{state, dispatch}}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, useAuthContext, useAuthDispatch, useAuthState };