import {
  Box, 
  Select as ChakraSelect
} from '@chakra-ui/react';
import Label from './Label';
import { inputStyle } from './Styles';


const Select = ({label, error, required, children, ...props}) => {
  return (
    <Box py={6}>
      <Label error={error} required={required}>{label}</Label>
      <ChakraSelect {...inputStyle} {...props}>
        {children}
      </ChakraSelect>
    </Box>
  );
}

export default Select;