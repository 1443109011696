import { gql } from '@apollo/client';

const UPDATE_FRAGMENT = gql`
  fragment CoreUpdateFields on Update {
    id
    status
    current
    comment
    author {
      id
      name
    }
    measureId
    methodId
    updatedAt
  }
`;

export default UPDATE_FRAGMENT;