import { gql } from '@apollo/client';


const DELETE_UPDATE = gql`
mutation DeleteUpdate($id:ID!) {
  deleteUpdate(input:{id:$id}) {
    update {
      id
      measureId
      methodId
    }
  }
}
`;

export default DELETE_UPDATE;