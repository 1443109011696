import {
  FormControl,
  Stack,
} from '@chakra-ui/react';
import { Button, Select, Textarea, TextInput } from '@components/forms';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_V2MOM } from '@queries/V2moms';
import LIST_COLLECTIONS from '@queries/ListCollections';
import { useNotifier } from '@utils/Notify';


const V2momForm = ({ initialValues, submitLabel }) => {
  const navigate = useNavigate();
  const { formSubmissionError, serverError } = useNotifier();

  const [addV2mom, {loading}] = useMutation(ADD_V2MOM,{
    onCompleted: (data) => {
      const { id } = data.addV2mom.v2mom
      navigate(`/v2moms/${id}/update`);
    },
    onError: (error) => {
      serverError(error.message);
    },
  });

  function validate(values) {
    const errors = {};
    if (!values.name) {
      errors.name = 'V2MOM name is required';
    }
    if (!values.collectionId) {
      errors.collectionId = 'Year is required'
    }

    return errors;
  }

  const formik = useFormik({
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: initialValues,
    validate,
    onSubmit: values => {
      addV2mom({ variables: { 
        id: values.id,
        collectionId: values.collectionId,
        name: values.name,
        vision: values.vision,
        values: values.values,
      }});
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (Object.keys(formik.errors).length !== 0) {
      formSubmissionError();
    }

    formik.handleSubmit();
  }

  const { loading: loadingCollections, error, data } = useQuery(LIST_COLLECTIONS);

  if (loadingCollections) return <p>Loading ...</p>;
  if (error) return `${error}`;

  const currentCollections = data.collections.filter(coll => coll.current === true );
  const collectionSelect = currentCollections.map((entry) => <option key={entry.id} value={entry.id}>{entry.name}</option>);

  return (
    <form onSubmit={handleSubmit}>
      <FormControl>
        <Select
          label='V2MOM year'
          id='collectionId'
          type='number'
          value={formik.values.collectionId ?? ''}
          error={formik.touched.collectionId && formik.errors.collectionId ? formik.errors.collectionId : null}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder='Select'
          required
        >
          {collectionSelect}
        </Select>
        <TextInput
          label='V2MOM Name'
          id='name'
          value={formik.values.name}
          error={formik.touched.name && formik.errors.name ? formik.errors.name : null}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder='e.g., Patchy MacDougal or Special Project'
          required
        />
        <Textarea
          label='Vision'
          id='vision'
          value={formik.values.vision}
          error={formik.touched.vision && formik.errors.vision ? formik.errors.vision : null}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder='Something visionary'
          markdown
          height={128}
        />
        <Textarea
          label='Values'
          id='values'
          value={formik.values.values}
          error={formik.touched.values && formik.errors.values ? formik.errors.values : null}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder='Something valuable'
          markdown
          height={128}
        />
        <Stack direction='row' spacing={4} mt={8} justifyContent='right'>
          <Button variant='ghost' onClick={() => navigate(-1)}>Cancel</Button>
          <Button variant='primary' type='submit' isLoading={loading}>{submitLabel}</Button>
        </Stack>
      </FormControl>
    </form>
  );
}

export default V2momForm;