import { Flex, FormLabel, Spacer } from '@chakra-ui/react';

const Required = () => {
  return (
    <>
      <Spacer />
      <FormLabel fontSize='xs' textTransform='uppercase' color='red.700'>Required</FormLabel>
    </>
  );
}

const Error = ({error}) => {
  return (
    <FormLabel fontSize='xs' color='red.700'>{error}</FormLabel>
  );
}

const Label = ({children, error, required, ...props}) => {
  return (
    <Flex>
      <FormLabel fontSize='xs' textTransform='uppercase' color='blackAlpha.700' {...props}>{children}</FormLabel>
      {error && <Error error={error} />}
      {required && <Required />}
    </Flex>
  );
}

export default Label;