import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'
import { GraphqlProvider } from '@adapters/apollo';
import { UserProvider } from '@contexts/UserProvider';
import { AuthProvider } from '@contexts/AuthProvider';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import reportWebVitals from './reportWebVitals';
import App from './App';

Sentry.init({
  dsn: "https://ecf92f1c23ca4aa38d99499f386d2559@o1307269.ingest.sentry.io/6551421",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <BrowserRouter>
        <ChakraProvider resetCSS>
          <GraphqlProvider>
            <AuthProvider>
              <UserProvider>
                <App />
              </UserProvider>
            </AuthProvider>
          </GraphqlProvider>
        </ChakraProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
